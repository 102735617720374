import { Box, PieChart } from "@frontend/ui";
import { PageHeader } from "@frontend/ui/page-header";
import { formatAddressCompact } from "@frontend/ui/utils/formatters";
import { useParams } from "react-router-dom";
import { useWithdrawalDetailsDistributionQuery } from "src/pages/risk-monitoring-staking/generated";
import WithdrawalDetailsTable from "./withdrawal-details-table";

const WithdrawalDrillDown = () => {
  const { address } = useParams();
  const { loading, data } = useWithdrawalDetailsDistributionQuery({ variables: { receiver: address || "" } });

  return (
    <Box>
      <PageHeader
        showBackButton
        pageTitle={formatAddressCompact(address || "")}
        extraData={
          address
            ? {
                text: address,
                allowCopy: true,
                hideText: true,
              }
            : undefined
        }
      />
      {(loading || (data?.withdrawalDetailsDistribution.length ?? 0) > 0) && (
        <PieChart
          title="Distribution of Recent Withdrawals"
          isLoading={loading}
          emptyState={!data?.withdrawalDetailsDistribution.length && !loading}
          series={[
            {
              data: data?.withdrawalDetailsDistribution.map((w) => [w.category, w.txValue]) ?? [],
            },
          ]}
        />
      )}
      <WithdrawalDetailsTable title="Future Transactions Sending ETH" />
    </Box>
  );
};

export default WithdrawalDrillDown;
