import { TimeSpan } from "@frontend/ui/echarts/types";

export const daysAgoOptions = [7, 30, 90, 365] as const;
type DaysAgo = (typeof daysAgoOptions)[number];

export const timeSpans = [TimeSpan.Week, TimeSpan.Month, TimeSpan.Quarter, TimeSpan.Year] as const;
export type PoolsHistoryTimeSpans = (typeof timeSpans)[number];

export const daysAgoByTimeSpan: Record<PoolsHistoryTimeSpans, DaysAgo> = {
  [TimeSpan.Week]: 7,
  [TimeSpan.Month]: 30,
  [TimeSpan.Quarter]: 90,
  [TimeSpan.Year]: 365,
};

export type DeltaTimeType = (typeof daysAgoOptions)[number];
