import { useCallback, useEffect, useState } from "react";
import {
  AptRewardClaimersTableRow,
  Chain,
  SortClaimersBy,
  SortOrder,
  useAptRewardClaimersTableLazyQuery,
} from "../../../generated";

type Props = {
  chain: Chain;
  selectedProtocol: string;
};

const pageSize = 10;

export const useRewardClaimersData = ({ chain, selectedProtocol }: Props) => {
  const [rewardClaimers, setRewardClaimers] = useState<AptRewardClaimersTableRow[]>([]);
  const [query, setQuery] = useState({
    order: SortOrder.Descending,
    sort: SortClaimersBy.AptClaimedFromAllProtocols,
    skip: 0,
  });
  const [fetchRewardClaimers, { loading }] = useAptRewardClaimersTableLazyQuery();

  useEffect(() => {
    void (async () => {
      const { data } = await fetchRewardClaimers({
        variables: {
          chain,
          selectedProtocol,
          limit: 100,
          skip: query.skip,
          sort: query.sort,
          order: query.order,
        },
      });
      if (data?.aptRewardClaimersTable) {
        setRewardClaimers((p) => (query.skip ? [...p, ...data.aptRewardClaimersTable] : data.aptRewardClaimersTable));
      }
    })();
  }, [fetchRewardClaimers, chain, selectedProtocol, query.order, query.skip, query.sort]);

  const onPageChange = useCallback(
    (page: number) => {
      if (rewardClaimers && pageSize * page === rewardClaimers.length) {
        setQuery((q) => ({ ...q, skip: rewardClaimers.length }));
      }
    },
    [rewardClaimers],
  );

  return {
    rewardClaimers,
    loading,
    onPageChange,
  };
};
