import { Box, PieChart, Grid } from "@frontend/ui";
import { PageHeader } from "@frontend/ui/page-header";
import { WithdrawalCards, EventsTable, WithdrawalsCharts } from "./components";
import { EventStrategy, EventTypeFilter, useDistributionByCategoryQuery } from "../../generated";

const Overview = () => {
  const { loading, data } = useDistributionByCategoryQuery();
  return (
    <Box>
      <PageHeader showBackButton={false} pageTitle="Withdrawals & Deposits" />
      <Box display="flex" flexDirection="column">
        <WithdrawalCards />
        <WithdrawalsCharts />
        <EventsTable
          title="Withdrawal Queue"
          eventType={EventTypeFilter.WithdrawalRequest}
          strategy={EventStrategy.EethEigenlayer}
          notProcessedRequests
          hideStrategyColumn
          showExportCsv
        />
        <EventsTable
          title="Withdrawal Events"
          eventType={EventTypeFilter.WithdrawalProcessed}
          showExportCsv
          showFilters
        />
        <Grid container>
          <Grid item xs={12}>
            <PieChart
              title="Distribution of Recent Withdrawals"
              isLoading={loading}
              series={[
                {
                  data: data?.withdrawalDistributionByCategory.map((w) => [w.category, w.txValue]) ?? [],
                },
              ]}
            />
          </Grid>
          <Grid item xs={12}>
            <PieChart
              title="Distribution of Deposit Sources"
              isLoading={loading}
              series={[
                {
                  data: data?.depositDistributionByCategory.map((w) => [w.category, w.txValue]) ?? [],
                },
              ]}
            />
          </Grid>
        </Grid>
        <EventsTable
          title="Deposit Events"
          eventType={EventTypeFilter.Deposit}
          strategy={EventStrategy.EethEigenlayer}
          hideStrategyColumn
          showExportCsv
        />
      </Box>
    </Box>
  );
};

export default Overview;
