import { FC } from "react";
import { Box, CryptoIcon, Tooltip, Typography } from "@frontend/ui";
import { wrappedSymbolToIconSymbol } from "@frontend/ui/utils/icon-helper";
import { formatAmount } from "@frontend/ui/utils/formatters";
import { Market, MarketData } from "src/pages/ccar-lending-page/generated";
import { useMarketSymbols } from "src/pages/ccar-lending-page/tabs/markets";
import { getHealthText, getHealthTooltip } from "src/pages/ccar-lending-page/utils/health-utils";
import { useClientConfig } from "src/pages/ccar-lending-page/clients-config";

interface MarketHealthScoreProps {
  market: Market;
  healthFactor: number;
  totalCollateral?: number;
  totalSupply?: number;
  totalBorrow?: number;
  eModeCategory?: { id: string; label: string };
  marketData?: MarketData;
}

type MarketFieldProps = {
  title: string;
  value: number | string;
  currency?: string;
};

const MarketField = ({ title, value, currency }: MarketFieldProps) => {
  const displayValue = typeof value === "string" ? value : formatAmount(value, { currency, notation: "compact" });
  const tooltipValue = typeof value === "string" ? "" : formatAmount(value, { currency, notation: "standard" });

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Typography variant="label">{title}</Typography>
      <Tooltip title={tooltipValue} placement="right-start">
        <Typography>{displayValue}</Typography>
      </Tooltip>
    </Box>
  );
};

export const MarketHealthScore: FC<MarketHealthScoreProps> = ({
  market,
  healthFactor,
  totalSupply,
  totalCollateral,
  totalBorrow,
  eModeCategory,
  marketData,
}) => {
  const { isHealthPercent, showChainMarketsBreakdown } = useClientConfig();
  const { marketSymbol1, marketSymbol2 } = useMarketSymbols(market);

  const mainSymbol = showChainMarketsBreakdown ? marketData?.chain : marketSymbol1;

  return (
    <Box display="flex" width="100%" alignItems="center" gap={[2, 3]}>
      {mainSymbol && (
        <Box sx={{ transform: { xs: "scale(0.8)", md: "none" } }} display="flex" alignSelf="flex-start" minHeight={48}>
          {marketSymbol2 && (
            <CryptoIcon
              icon={wrappedSymbolToIconSymbol(marketSymbol2.toLowerCase())}
              sx={{
                marginTop: { xs: -1.5, md: -3 },
                img: { width: "48px", height: "48px" },
              }}
            />
          )}
          <CryptoIcon
            icon={wrappedSymbolToIconSymbol(mainSymbol.toLowerCase())}
            sx={{
              marginLeft: { xs: marketSymbol2 ? -1.5 : 0, md: marketSymbol2 ? -2 : 0 },
              marginBottom: { xs: marketSymbol2 ? -1.5 : 0, md: marketSymbol2 ? -3 : 0 },
              img: { width: "48px", height: "48px" },
            }}
          />
        </Box>
      )}
      <Box
        display="flex"
        flexGrow={1}
        alignItems="flex-start"
        justifyContent="space-between"
        sx={{ flexDirection: { xs: "column", md: "row" } }}
        gap={[0, 2]}
      >
        {marketData && <MarketField title="Market" value={marketData.name} />}
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="label">Health Score</Typography>
          <Tooltip title={getHealthTooltip(healthFactor || 0, isHealthPercent)}>
            <Typography
              fontFamily="Archivo"
              fontStyle="normal"
              fontWeight={400}
              fontSize={healthFactor === Number.MAX_VALUE ? 24 : 14}
              lineHeight={healthFactor === Number.MAX_VALUE ? 1.5 : undefined}
            >
              {getHealthText(healthFactor || 0, isHealthPercent)}
            </Typography>
          </Tooltip>
        </Box>
        {eModeCategory && <MarketField title="E-Mode" value={eModeCategory.label} currency="USD" />}
        {!totalCollateral && !totalBorrow && totalSupply !== undefined ? (
          <MarketField title="Supply" value={totalSupply} currency="USD" />
        ) : (
          <>
            {totalCollateral !== undefined && <MarketField title="Collateral" value={totalCollateral} currency="USD" />}
            {totalBorrow !== undefined && <MarketField title="Borrow" value={totalBorrow} currency="USD" />}
          </>
        )}
      </Box>
    </Box>
  );
};
