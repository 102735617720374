import { StackedBarChart } from "@frontend/ui";
import { capitalizeFirstLetter } from "@frontend/ui/utils/formatters";
import { Chain, useClaimedWalletsAvgDifferentProtocolUsedQuery } from "../../../generated";

type Props = {
  chain: Chain;
};

export const AverageProtocolClaimsPerWallet = ({ chain }: Props) => {
  const { data, loading } = useClaimedWalletsAvgDifferentProtocolUsedQuery({
    variables: { chain },
  });

  return (
    <StackedBarChart
      chartHeight={325}
      title="Average Protocol Claims per Wallet"
      description="Average number of additional protocols a wallet interacts with per protocol"
      isLoading={loading}
      series={[
        {
          label: "Protocols",
          data: [...(data?.claimedWalletsAvgDifferentProtocolUsed || [])]
            .sort((a, b) => b.value - a.value)
            .map((c) => [capitalizeFirstLetter(c.category), c.value]),
        },
      ]}
      showXAxisLine={false}
      showLegend={false}
      currency=""
    />
  );
};
