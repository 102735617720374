import { useParams } from "react-router-dom";
import { useMemo } from "react";
import { CCARClientConfig } from "./types";
import { Client } from "../types";
import {
  aaveV3Config,
  aaveV2Config,
  benqiConfig,
  radiantConfig,
  venusConfig,
  seamlessConfig,
  zerolendConfig,
} from "./clients";

export const clientsConfig: Record<Client, CCARClientConfig> = {
  aave: aaveV3Config,
  "aave-v2": aaveV2Config,
  benqi: benqiConfig,
  radiant: radiantConfig,
  venus: venusConfig,
  seamless: seamlessConfig,
  zerolend: zerolendConfig,
};
export const useClientConfig = (): CCARClientConfig => {
  const { clientName } = useParams<{ clientName: Client }>();

  const clientConfig = useMemo(() => {
    const config = clientsConfig[clientName!];
    const { isMultiMarket, isMultichain, marketType } = config;
    const showChainMarketsBreakdown = isMultiMarket && isMultichain && marketType === "GeneralMarket";
    return {
      ...config,
      showChainMarketsBreakdown,
    };
  }, [clientName]);

  return clientConfig;
};
