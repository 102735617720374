import { Header, RenderData } from "@frontend/types";
import { ChaosTable, percentCell, textCell, valueCell } from "@frontend/ui";
import { capitalizeFirstLetter } from "@frontend/ui/utils/formatters";
import { useParams } from "react-router-dom";
import { RouteParams, RoutePath } from "../../../../../config/routes";
import { Chain, useProtocolBreakDownTableQuery } from "../../../generated";

const headers: Header[] = [
  {
    renderType: "TEXT",
    text: "Protocol",
  },
  {
    renderType: "TEXT",
    text: "total APT distributed",
  },
  {
    renderType: "TEXT",
    text: "APT claimed by users",
  },
  {
    renderType: "TEXT",
    text: "number of claimed wallets",
  },
  {
    renderType: "TEXT",
    text: "APT Allocation percentage",
  },
];

type Props = {
  chain: Chain;
};

export const ProtocolBreakDownTable = ({ chain }: Props) => {
  const { clientName } = useParams();
  const { data: protocolBreakdown, loading } = useProtocolBreakDownTableQuery({
    variables: { chain },
  });
  const tableData: RenderData[][] = (protocolBreakdown?.protocolBreakDownTable || []).map((row) => [
    textCell(
      capitalizeFirstLetter(row.protocol),
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      capitalizeFirstLetter(row.protocol),
    ),
    valueCell(row.nativeTokenDistributed),
    valueCell(row.nativeTokenClaimed),
    valueCell(row.numberClaimedWallet),
    percentCell(row.nativeTokenAllocationPercent),
  ]);

  const handleOnClickRow = (rowIdx: number) => {
    const row = (protocolBreakdown?.protocolBreakDownTable || [])[rowIdx];

    if (!row) return "";

    return RoutePath.Risk.IncentiveOverviewDetails.replace(RouteParams.ClientName, clientName!).replace(
      RouteParams.protocol,
      row.protocol,
    );
  };

  return (
    <ChaosTable
      title="Protocol Breakdown"
      headers={headers}
      data={tableData}
      isLoading={loading}
      showRowChevron
      rowHref={handleOnClickRow}
    />
  );
};
