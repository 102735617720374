import { Grid } from "@frontend/ui";
import { getDeltaFields } from "@frontend/ui/trend";
import { ValueCard, ValueCardProps } from "@frontend/ui/value-card";
import { StableCoinCard } from "src/pages/ccar-lending-page/types";
import { useClientConfig } from "src/pages/ccar-lending-page/clients-config";
import { useMemo } from "react";
import { useStablecoinDataContext } from "../../provider";
import { useGhoFacilitatorsQuery } from "../../../../generated";

export const StablecoinCards = () => {
  const { stableCoin } = useClientConfig();
  const { history, marketAssets = {}, price, assetStats, marketCap, volume } = useStablecoinDataContext();
  const { data: ghoFacilitatorsResponse } = useGhoFacilitatorsQuery({
    skip: !stableCoin?.cards?.includes("totalMinted"),
  });
  const distribution = Object.values(history ?? {});

  const collateralAssets = Object.values(marketAssets)
    .filter((ma) => !stableCoin?.marketType || ma.marketType === stableCoin?.marketType)
    .filter((ma) => ma.asset !== stableCoin?.asset);

  const stableCoinAssets = Object.values(marketAssets).filter((ma) => ma.asset === stableCoin?.asset);

  const currentStats = assetStats?.current;
  const previousStats = assetStats?.previous;

  const { totalBorrowToken: currTotalBorrowToken, borrowCap } = currentStats || {};

  const { totalBorrowToken: prevTotalBorrowToken } = previousStats || {};

  const facilitators = useMemo(() => {
    if (!ghoFacilitatorsResponse?.ghoFacilitators) return {};
    const currentLevel = ghoFacilitatorsResponse.ghoFacilitators.current.reduce(
      (sum, curr) => curr.currentLevel + sum,
      0,
    );
    const currentMaxCapacity = ghoFacilitatorsResponse.ghoFacilitators.current.reduce(
      (sum, curr) => curr.maxCapacity + sum,
      0,
    );
    const previousLevel =
      ghoFacilitatorsResponse.ghoFacilitators.previous?.reduce((sum, curr) => curr.currentLevel + sum, 0) ?? 0;
    const previousMaxCapacity =
      ghoFacilitatorsResponse.ghoFacilitators.previous?.reduce((sum, curr) => curr.maxCapacity + sum, 0) ?? 0;

    return {
      currentLevel,
      currentMaxCapacity,
      previousLevel,
      previousMaxCapacity,
    };
  }, [ghoFacilitatorsResponse]);

  const cardsConfig: Record<StableCoinCard, ValueCardProps> = {
    totalMinted: {
      title: "Total Minted",
      value: facilitators.currentLevel ?? undefined,
      ...getDeltaFields(facilitators.currentLevel, facilitators.previousLevel || undefined, true),
      progress: facilitators.currentMaxCapacity
        ? {
            maxValue: facilitators.currentMaxCapacity,
            displayMaxValue: true,
          }
        : undefined,
      valueMaximumFractionDigits: facilitators.currentMaxCapacity ? 1 : undefined,
    },
    totalBorrow: {
      title: "Total Borrow",
      value: currTotalBorrowToken ?? undefined,
      ...getDeltaFields(currTotalBorrowToken || undefined, prevTotalBorrowToken || undefined, true),
      progress: borrowCap
        ? {
            maxValue: borrowCap,
            displayMaxValue: true,
          }
        : undefined,
      valueMaximumFractionDigits: borrowCap ? 1 : undefined,
    },
    totalSupply: {
      value: stableCoinAssets?.reduce((curr, next) => curr + next.totalSupply, 0),
      title: `${stableCoin?.asset || ""} Supply`,
      currency: "USD",
    },
    totalCollateral: {
      value: collateralAssets?.reduce((curr, next) => curr + next.totalSupply, 0),
      title: "Mint Collateral",
      currency: "USD",
    },
    price: {
      value: price,
      title: "Price",
      currency: "USD",
      valueMaximumFractionDigits: 10,
    },
    collateraDebtRatio: {
      value: distribution[distribution.length - 1]?.averageCollateralRatio,
      title: "Collateral Ratio",
      isPercent: true,
    },
    marketCap: {
      value: marketCap,
      title: "Market Cap",
      currency: "USD",
    },
    volume: {
      value: volume,
      title: "Volume 24H",
      currency: "USD",
    },
  };

  return (
    <Grid container>
      {stableCoin?.cards?.map((cardName: StableCoinCard) => (
        <Grid key={cardName} item xs={12} sm={4} lg={3}>
          <ValueCard {...cardsConfig[cardName]} disableContainerSpaceBetween />
        </Grid>
      ))}
    </Grid>
  );
};
