import { FC } from "react";
import { Grid, StackedBarChart } from "@frontend/ui";
import { formatDay } from "@frontend/ui/utils/formatters";
import { Chain, useVolumeChartsByProtocolQuery } from "../../../generated";

interface AptVolumeChartsProps {
  chain: Chain;
  selectedProtocol: string;
}

const CHART_HEIGHT = 348;

export const AptVolumeCharts: FC<AptVolumeChartsProps> = ({ chain, selectedProtocol }) => {
  const { loading, data } = useVolumeChartsByProtocolQuery({
    variables: {
      chain,
      selectedProtocol,
    },
  });

  return (
    <Grid container>
      <Grid item xs={6}>
        <StackedBarChart
          title="Volume Over Time"
          series={[
            {
              label: "Volume",
              data: (data?.volumeDataByProtocol || []).map((item) => [
                formatDay(new Date(item.timestamp)),
                item.categories.reduce((acc, curr) => acc + curr.value, 0),
              ]),
            },
          ]}
          showLegend={false}
          showXAxisLine={false}
          currency="USD"
          chartHeight={CHART_HEIGHT}
          isLoading={loading}
        />
      </Grid>
      <Grid item xs={6}>
        <StackedBarChart
          title="Staking Activity"
          series={[
            {
              label: "Staked",
              data: (data?.stakingActivityDataByProtocol || []).map((item) => [
                formatDay(new Date(item.timestamp)),
                item.categories.find((category) => category.category === "Staked")?.value || 0,
              ]),
            },
            {
              label: "Unstaked",
              data: (data?.stakingActivityDataByProtocol || []).map((item) => [
                formatDay(new Date(item.timestamp)),
                item.categories.find((category) => category.category === "Unstaked")?.value || 0,
              ]),
            },
          ]}
          showXAxisLine={false}
          currency="USD"
          chartHeight={CHART_HEIGHT}
          isLoading={loading}
        />
      </Grid>
    </Grid>
  );
};
