import { Header, RenderData } from "@frontend/types";
import { ChaosTable, currencyCell, dateCell, externalLinkAddressCell, textCell } from "@frontend/ui";
import { useParams } from "react-router-dom";
import { useWithdrawalDetailsTableQuery } from "src/pages/risk-monitoring-staking/generated";
import { Chain } from "../../../../ccar-lending-page/generated";

type Props = {
  title: string;
};

const headers: Header[] = [
  {
    renderType: "TEXT",
    text: "Date",
    field: "date",
  },
  {
    renderType: "TEXT",
    text: "TX Hash",
    nonSortable: true,
  },
  {
    renderType: "TEXT",
    text: "Receiver",
    nonSortable: true,
  },
  {
    renderType: "TEXT",
    text: "Category",
    nonSortable: true,
  },
  {
    renderType: "TEXT",
    text: "Amount",
    field: "amount",
    sortOpposite: true,
  },
];

const WithdrawalDetailsTable = ({ title }: Props) => {
  const { address } = useParams();
  const { loading, data } = useWithdrawalDetailsTableQuery({ variables: { receiver: address || "" } });
  const transactions = data?.withdrawalDetailsTable;

  const tableData: RenderData[][] = (transactions || []).map((tx) => [
    dateCell(new Date(tx.date)),
    externalLinkAddressCell(tx.txHash, Chain.Ethereum, "tx"),
    externalLinkAddressCell(tx.receiver || "", Chain.Ethereum, "account"),
    textCell(tx.category),
    currencyCell(tx.amount, { cryptoCurrency: "ETH" }),
  ]);

  return (
    <ChaosTable
      title={title}
      initialSortBy={0} // Default sort by date
      isInitialSortDesc
      isFullHeight
      showSearch
      pageSize={10}
      headers={headers}
      data={tableData}
      isLoading={loading}
      isFilterHidden
    />
  );
};

export default WithdrawalDetailsTable;
