import { Box, Grid } from "@frontend/ui";
import { PageHeader } from "@frontend/ui/page-header";
import { useOverviewData } from "./use-overview-data";
import {
  BridgeNetFlowByChainChart,
  BridgeNetFlowByTokenChart,
  OverviewCards,
  StablecoinAmountHistory,
  TvlByDappCategoryChart,
} from "./components";

const Overview = () => {
  const data = useOverviewData();
  return (
    <Box display="flex" flexDirection="column">
      <PageHeader showBackButton={false} pageTitle="Overview" />
      <OverviewCards data={data} />
      <TvlByDappCategoryChart data={data} />
      <Grid container>
        <Grid item xs={12} md={6}>
          <BridgeNetFlowByChainChart data={data} />
        </Grid>
        <Grid item xs={12} md={6}>
          <BridgeNetFlowByTokenChart data={data} />
        </Grid>
      </Grid>
      <StablecoinAmountHistory data={data} />
    </Box>
  );
};

export default Overview;
