import { useParams } from "react-router-dom";
import { Box } from "@frontend/ui";
import { PoolFragment } from "src/pages/ccar-lending-page/generated";
import { PoolOverview, PoolCompositionChart } from "./components";

type Props = {
  pool: PoolFragment;
};

const PoolDetails = ({ pool }: Props) => {
  const { poolId } = useParams();

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <PoolOverview pool={pool} />
      <PoolCompositionChart pool={pool} poolAddress={poolId!} />
    </Box>
  );
};

export default PoolDetails;
