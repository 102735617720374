import { TimeSpan } from "@frontend/ui/echarts/types";
import { Chain, useChainStakingActivityOverTimeQuery } from "../../../generated";
import ChainCategoryHistoryChart from "../../components/chain-category-history-chart";

type Props = {
  chain: Chain;
  timeSpan: TimeSpan;
};

export const StakingActivityOfRewardClaimers = ({ chain, timeSpan }: Props) => {
  const { data, loading } = useChainStakingActivityOverTimeQuery({
    variables: { chain, daysAgo: null },
  });

  return (
    <ChainCategoryHistoryChart
      data={data?.chainStakingActivityOverTime}
      title="Staking Activity of Reward Claimers"
      yAxisLabel="APT"
      isLoading={loading}
      timeSpan={timeSpan}
      showMonthTooltip={false}
      cryptoCurrency="APT"
    />
  );
};
