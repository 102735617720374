import { StackedBarChart } from "@frontend/ui";
import { Chain, useAvgHoldTimeByProtocolQuery } from "../../../generated";

type Props = {
  chain: Chain;
};

export const AverageHoldTimePerProtocol = ({ chain }: Props) => {
  const { data, loading } = useAvgHoldTimeByProtocolQuery({
    variables: { chain },
  });

  return (
    <StackedBarChart
      chartHeight={325}
      title="Average Hold Time of APT Rewards per Protocol"
      isLoading={loading}
      series={[
        {
          label: "Days",
          data: [...(data?.avgHoldTimeByProtocol || [])]
            .sort((a, b) => b.value - a.value)
            .map((c) => [c.category, c.value]),
        },
      ]}
      showXAxisLine={false}
      showLegend={false}
      cryptoCurrency="Days"
      rotateXAxis={45}
    />
  );
};
