import { Box } from "@frontend/ui";
import { PageHeader } from "@frontend/ui/page-header";
import { formatAddressCompact } from "@frontend/ui/utils/formatters";
import { useParams } from "react-router-dom";
import EventsTable from "./components/events-table";
import { RewardClaimerCharts } from "./components/reward-claimer-charts";
import { useClientConfig } from "../../clients-config";

const RewardClaimerDetails = () => {
  const { chain } = useClientConfig();
  const { address, protocol } = useParams();

  return (
    <Box>
      <PageHeader showBackButton pageTitle={formatAddressCompact(address || "")} />
      <EventsTable address={address!} selectedProtocol={protocol!} chain={chain} />
      <RewardClaimerCharts address={address!} selectedProtocol={protocol!} chain={chain} />
    </Box>
  );
};

export default RewardClaimerDetails;
