import { useState } from "react";
import { PageHeader } from "@frontend/ui/page-header";
import { Box, Grid } from "@frontend/ui";
import { TimeSpan } from "../../generated";
import { TimeSpanPicker } from "../../../ccar-lending-page/components/time-span-picker";
import IncentiveOverviewCards from "./components/incentive-overview-cards";
import { ProtocolBreakDownTable } from "./components/protocol-breakdown-table";
import { AptDistributionPerProtocolOverTime } from "./components/apt-distribuition-per-protocol";
import { useClientConfig } from "../../clients-config";
import { TotalAptDistributionPerProtocol } from "./components/total-apt-per-protocol";
import { RetentionRatePerProtocol } from "./components/retention-rate-per-protocol";
import { TotalActivityOvertimeForRewardClaimers } from "./components/total-activity-overtime-for-reward-claimers";
import { StakingActivityOfRewardClaimers } from "./components/staking-activity-of-reward-claimers";
import { AverageHoldTimePerProtocol } from "./components/average-hold-time-per-protocol";
import { AverageProtocolClaimsPerWallet } from "./components/avg-protocol-claims-per-wallet";

const IncentiveOverview = () => {
  const { chain } = useClientConfig();
  const [timeSpan, setTimeSpan] = useState<TimeSpan>(TimeSpan.Month);

  return (
    <Box>
      <PageHeader showBackButton={false} pageTitle="Incentive Overview" />
      <IncentiveOverviewCards chain={chain} />
      <Box display="flex" justifyContent="end">
        <TimeSpanPicker
          timeSpanOptions={[TimeSpan.Month, TimeSpan.Quarter, TimeSpan.Year]}
          selectedTimeSpan={timeSpan}
          onSelectTimeSpan={setTimeSpan}
        />
      </Box>

      <ProtocolBreakDownTable chain={chain} />
      <Grid container>
        <Grid item xs={12} md={6}>
          <AptDistributionPerProtocolOverTime chain={chain} timeSpan={timeSpan} />
        </Grid>
        <Grid item xs={12} md={6}>
          <TotalAptDistributionPerProtocol chain={chain} />
        </Grid>
        <Grid item xs={12} md={6}>
          <RetentionRatePerProtocol chain={chain} />
        </Grid>
        <Grid item xs={12} md={6}>
          <TotalActivityOvertimeForRewardClaimers chain={chain} timeSpan={timeSpan} />
        </Grid>
        <Grid item xs={12} md={6}>
          <StakingActivityOfRewardClaimers chain={chain} timeSpan={timeSpan} />
        </Grid>
        <Grid item xs={12} md={6}>
          <AverageHoldTimePerProtocol chain={chain} />
        </Grid>
      </Grid>
      <AverageProtocolClaimsPerWallet chain={chain} />
    </Box>
  );
};

export default IncentiveOverview;
