import { CustomReactSelect } from "@frontend/ui";
import { PageHeader } from "@frontend/ui/page-header";
import { capitalizeFirstLetter } from "@frontend/ui/utils/formatters";
import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import { RouteParams } from "../../../../config/route-params";
import { RoutePath } from "../../../../config/routes";
import { useClientConfig } from "../../clients-config";
import { useProtocolBreakDownTableQuery } from "../../generated";
import IncentiveDetailsCards from "./components/incentive-details-cards";
import { AptRewardClaimersTable } from "./components/apt-reward-claimers";
import { AptDistributedVsClaimed } from "./components/apt-distributed-vs-claimed";
import { AptVolumeCharts } from "./components/apt-volume-charts";
import { ApyVsDepositAndWithdrawal } from "./components/apy-vs-deposit-withdraw";

const IncentiveOverviewDetails = () => {
  const { chain } = useClientConfig();
  const { protocol, clientName } = useParams();

  const { data: protocolBreakdown, loading } = useProtocolBreakDownTableQuery({
    variables: { chain },
  });
  const protocolsList = protocolBreakdown?.protocolBreakDownTable?.map((row) => row.protocol) || [];
  const dropdownOptions = protocolsList.map((p) => ({
    value: p,
    label: capitalizeFirstLetter(p),
    cryptoIcon: p,
    link: RoutePath.Risk.IncentiveOverviewDetails.replace(RouteParams.ClientName, clientName!).replace(
      RouteParams.protocol,
      p,
    ),
  }));

  const selectedProtocol = dropdownOptions.find((opt) => opt.value === protocol);

  if (!selectedProtocol?.value) {
    return null;
  }

  return (
    <Box>
      <PageHeader
        backLink={RoutePath.Risk.IncentiveOverview.replace(RouteParams.ClientName, clientName!)}
        pageTitle={
          <CustomReactSelect
            options={dropdownOptions}
            value={selectedProtocol ? { ...selectedProtocol } : undefined}
            variant="h3"
            isLoading={loading}
          />
        }
      />
      <IncentiveDetailsCards chain={chain} selectedProtocol={selectedProtocol.value} />
      <AptRewardClaimersTable chain={chain} selectedProtocol={selectedProtocol.value} />
      <AptDistributedVsClaimed chain={chain} selectedProtocol={selectedProtocol.value} />
      <ApyVsDepositAndWithdrawal chain={chain} selectedProtocol={selectedProtocol.value} daysAgo={90} />
      <AptVolumeCharts chain={chain} selectedProtocol={selectedProtocol.value} />
    </Box>
  );
};

export default IncentiveOverviewDetails;
