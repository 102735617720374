import { CompositionOverTimeChart } from "@frontend/ui";
import { NumberChartSeries } from "@frontend/ui/echarts/types";
import { OverviewData } from "../use-overview-data";
import { TokenHistoryValue } from "../../../generated";
import { useXAxisSplit } from "../../../chart-utils";

type Props = {
  data: OverviewData;
};

const BridgeNetFlowByChainChart = ({ data }: Props) => {
  const xAxisSplit = useXAxisSplit();

  const chartData = (data.bridgeNetFlowsByToken || []).reduce(
    (acc: Record<string, NumberChartSeries>, d: TokenHistoryValue) => {
      const isInbound = d.value > 0;
      const key = `${d.token}-${isInbound ? "inbound" : "outbound"}`;

      const prevSeries = acc[key];
      const prevData = prevSeries?.data || [];
      const newData = [...prevData, [d.timestamp, d.value]] as [number, number][];

      const newSeries: NumberChartSeries = {
        label: d.token,
        data: newData,
        type: "bar",
      };

      return { ...acc, [key]: newSeries };
    },
    {},
  );

  return (
    <CompositionOverTimeChart
      chartHeight={325}
      title="Bridge Inflows & Outflows By Token"
      series={Object.values(chartData)}
      currency="USD"
      isLoading={data.isLoadingBridgeNetFlowsByToken}
      sortTooltipValues
      xAxisSplitNumber={xAxisSplit}
    />
  );
};

export default BridgeNetFlowByChainChart;
