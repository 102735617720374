import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { WalletSupplyAndBorrow, useWalletTotalSupplyAndBorrowQuery } from "../../generated";
import { useClientConfig } from "../../clients-config";

dayjs.extend(utc);

type ReturnType = {
  supplyAndBorrow?: WalletSupplyAndBorrow;
  isLoading: boolean;
};

export const useWalletSupplyAndBorrow = (address: string): ReturnType => {
  const { walletDetails } = useClientConfig();

  const { data: walletTotalSupplyAndBorrow, loading: isLoadingTotalSupplyAndBorrow } =
    useWalletTotalSupplyAndBorrowQuery({
      variables: { address },
      skip: !walletDetails?.tables.includes("totalSupplyAndBorrow"),
    });

  const supplyAndBorrow = walletTotalSupplyAndBorrow?.walletTotalSupplyAndBorrow as WalletSupplyAndBorrow;

  return {
    isLoading: isLoadingTotalSupplyAndBorrow,
    supplyAndBorrow,
  };
};
