import { NumberChartSeries } from "@frontend/ui/echarts/types";
import { CompositionOverTimeChart, ValueOverTimeChart } from "@frontend/ui";
import { Chain, ChainCategoryHistoryValue, useProtocolDetailsDistributedVsClaimedQuery } from "../../../generated";

type Props = {
  chain: Chain;
  selectedProtocol: string;
};
export const AptDistributedVsClaimed = ({ chain, selectedProtocol }: Props) => {
  const { data, loading } = useProtocolDetailsDistributedVsClaimedQuery({
    variables: { chain, selectedProtocol, daysAgo: 90 },
  });

  if (
    !loading &&
    (!data || !data.protocolDetailsDistributedVsClaimed || !data.protocolDetailsDistributedVsClaimed.length)
  ) {
    return null;
  }

  const series = Object.values(
    (data?.protocolDetailsDistributedVsClaimed || []).reduce(
      (acc: Record<string, NumberChartSeries>, d: ChainCategoryHistoryValue) => {
        const { categories } = d;

        categories.forEach((c) => {
          const prevSeries = acc[c.category];
          const prevData = prevSeries?.data || [];
          const newData = [...prevData, [d.timestamp, c.category === "Distributed" ? -c.value : c.value]] as [
            number,
            number,
          ][];
          const newSeries: NumberChartSeries = {
            label: c.category,
            data: newData,
            type: "bar",
          };
          acc[c.category] = newSeries;
        });
        return acc;
      },
      {},
    ),
  );

  return (
    <CompositionOverTimeChart
      series={series}
      isLoading={loading}
      title="APT Distributed vs Claimed"
      yAxisLabel="APT"
      currency=""
      cryptoCurrency="APT"
      emptyState={data && !data.protocolDetailsDistributedVsClaimed.length}
      emptyStateText="No data available"
    />
  );
};
