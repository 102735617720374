import { Header, RenderData } from "@frontend/types";
import { ChaosTable, dateCell, numberCell, textCell } from "@frontend/ui";
import { capitalizeFirstLetter, formatAddressCompact } from "@frontend/ui/utils/formatters";
import { Chain, SortClaimerRecentEvents, SortOrder, useClaimerRecentEventsQuery } from "../../../generated";
import { getEventIcon } from "../../../../ccar-lending-page/components/events-table/utils";

type Props = {
  chain: Chain;
  selectedProtocol: string;
  address: string;
};

const EventsTable = ({ chain, selectedProtocol, address }: Props) => {
  const { data, loading } = useClaimerRecentEventsQuery({
    variables: {
      chain,
      selectedProtocol,
      address,
    },
  });

  const tableData: RenderData[][] = (data?.claimerRecentEvents || []).map((row) => [
    dateCell(new Date(row.timestamp)),
    textCell(capitalizeFirstLetter(row.protocolName)),
    { ...textCell(capitalizeFirstLetter(row.action)), renderType: "CHIP", customIcon: getEventIcon(row.action) },
    numberCell(row.amount),
    textCell(formatAddressCompact(row.txHash)),
  ]);
  const headers: Header[] = [
    {
      renderType: "TEXT",
      text: "Date",
      isDate: true,
    },
    {
      renderType: "TEXT",
      text: "Protocol Name",
    },
    {
      renderType: "TEXT",
      text: "Action",
      width: "16%",
    },
    {
      renderType: "TEXT",
      text: "Amount",
    },
    {
      renderType: "TEXT",
      text: "TX Hash",
    },
  ];

  return (
    <ChaosTable
      title="Recent Events"
      headers={headers}
      data={tableData}
      isLoading={loading}
      pageSize={10}
      isInitialSortDesc
      isFilterHidden
      initialSortBy={0}
    />
  );
};

export default EventsTable;
