import { useFeatureFlag, useFeatureFlags } from "../../utils/feature-flags";

export type ProtocolGroupsOptions = {
  label: string;
  value: string;
  icon?: string;
  featureFlag?: string;
}[];

export type ProtocolGroupType = "lending" | "perp" | "chain-analytics" | "leverage" | "staking" | "defiapp";

export type ProtocolGroupMetadata = {
  label: string;
  subLabel?: string;
  icon: string;
  type: ProtocolGroupType;
  category?: ProtocolGroupType;
  options?: ProtocolGroupsOptions;
};

type ProtocolGroups = Record<string, ProtocolGroupMetadata>;

export const groups: ProtocolGroups = {
  aave: {
    label: "Aave",
    icon: "aave",
    type: "lending",
    options: [
      { label: "V2 Mainnet", value: "aave-v2" },
      { label: "V3 Mainnet", value: "aave" },
    ],
  },
  benqi: {
    label: "Benqi",
    icon: "benqi",
    type: "lending",
  },
  gmx: {
    label: "GMX",
    icon: "gmx",
    type: "perp",
    options: [
      {
        label: "V1 Arbitrum",
        icon: "arbitrum",
        value: "gmx-arbitrum",
      },
      {
        label: "V1 Avalanche",
        icon: "avalanche",
        value: "gmx-avalanche",
      },
      {
        label: "V2 Arbitrum",
        icon: "arbitrum",
        value: "gmx-v2-arbitrum",
        featureFlag: "gmx_v2",
      },
      {
        label: "V2 Avalanche",
        icon: "avalanche",
        value: "gmx-v2-avalanche",
        featureFlag: "gmx_v2",
      },
    ],
  },
  "dydx-v4": {
    label: "dYdX",
    subLabel: "Chain",
    icon: "dydx",
    type: "perp",
  },
  ostium: {
    label: "Ostium",
    icon: "ostium",
    type: "perp",
    options: [{ label: "Mainnet", value: "ostium" }],
  },
  bluefin: {
    label: "Bluefin",
    icon: "bluefin",
    type: "perp",
  },
  radiant: {
    label: "Radiant",
    icon: "radiant",
    type: "lending",
  },
  venus: {
    label: "Venus",
    icon: "venus",
    type: "lending",
  },
  seamless: {
    label: "Seamless",
    icon: "seamless",
    type: "lending",
  },
  zerolend: {
    label: "ZeroLend",
    icon: "zerolend",
    type: "lending",
  },
  nftperp: {
    label: "NftPerp",
    icon: "nftperp",
    type: "perp",
  },
  jupiter: {
    label: "Jupiter",
    icon: "Jupiter",
    type: "perp",
  },
  synfutures: {
    label: "SynFutures",
    icon: "synfutures",
    type: "perp",
  },
  aptos: {
    label: "Aptos",
    icon: "aptos",
    type: "chain-analytics",
  },
  gearbox: {
    label: "Gearbox",
    icon: "gearbox",
    type: "lending",
    category: "leverage",
  },
  etherfi: {
    label: "Etherfi",
    icon: "etherfi",
    type: "staking",
  },
  defiapp: {
    label: "DefiApp",
    icon: "defiapp",
    type: "defiapp",
  },
};

export const displayProtocolTypes = ["lending", "perp", "staking"] as const;

export type DisplayProtocolType = (typeof displayProtocolTypes)[number];

export const labelByProtocolType: Record<DisplayProtocolType, string> = {
  lending: "Borrow & Lend",
  perp: "Perpetual",
  staking: "Restaking",
};

export const useProtocolGroups = () => {
  const featureFlags = useFeatureFlags();
  const dydxEnabled = useFeatureFlag("dydx-v4");
  const ostiumEnabled = useFeatureFlag("ostium");
  const seamlessEnabled = useFeatureFlag("seamless");
  const zerolendEnabled = useFeatureFlag("zerolend");
  const bluefinEnabled = useFeatureFlag("bluefin");
  const nftperpEnabled = useFeatureFlag("nftperp");
  const jupiterEnabled = useFeatureFlag("jupiter");
  const synFuturesEnabled = useFeatureFlag("synfutures");
  const aptosEnabled = useFeatureFlag("aptos");
  const etherfiEnabled = useFeatureFlag("etherfi");

  const cloned = { ...groups };

  if (!dydxEnabled) {
    delete cloned["dydx-v4"];
  }

  if (!ostiumEnabled) {
    delete cloned.ostium;
  }

  if (!seamlessEnabled) {
    delete cloned.seamless;
  }

  if (!zerolendEnabled) {
    delete cloned.zerolend;
  }

  if (!bluefinEnabled) {
    delete cloned.bluefin;
  }

  if (!nftperpEnabled) {
    delete cloned.nftperp;
  }

  if (!jupiterEnabled) {
    delete cloned.jupiter;
  }

  if (!synFuturesEnabled) {
    delete cloned.synfutures;
  }

  if (!aptosEnabled) {
    delete cloned.aptos;
  }

  if (!etherfiEnabled) {
    delete cloned.etherfi;
  }

  const groupsWithFilteredOptions = Object.entries(cloned).reduce<ProtocolGroups>((acc, [key, value]) => {
    const filteredOptions = value.options?.filter((option) => {
      if (!option.featureFlag) return true;
      return featureFlags.some((flag) => flag.key === option.featureFlag && flag.enabled);
    });

    return {
      ...acc,
      [key]: {
        ...value,
        options: (filteredOptions?.length || 0) > 1 ? filteredOptions : undefined,
      },
    };
  }, {});

  return groupsWithFilteredOptions;
};
