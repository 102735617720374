import BitcoinLogoImage from "../assets/img/bitcoin-logo.svg";
import ConsideredFinanceImage from "../assets/img/considered-finance.svg";
import OneInchImage from "../assets/img/crypto/1inch.svg";
import ThreeCrvImage from "../assets/img/crypto/3crv.svg";
import BnbxImage from "../assets/img/crypto/BNBx.svg";
import HayImage from "../assets/img/crypto/HAY.svg";
import RacaImage from "../assets/img/crypto/RACA.svg";
import UsdcGrvImage from "../assets/img/crypto/USDCgrv.svg";
import UsdtGrvImage from "../assets/img/crypto/USDTgrv.svg";
import AaveImage from "../assets/img/crypto/aave.svg";
import AevoImage from "../assets/img/crypto/aevo.webp";
import AgeurImage from "../assets/img/crypto/ageur.svg";
import AgixImage from "../assets/img/crypto/agix.svg";
import AktImage from "../assets/img/crypto/akt.svg";
import AlgorandImage from "../assets/img/crypto/algorand.svg";
import AlpacaImage from "../assets/img/crypto/alpaca.svg";
import AmplImage from "../assets/img/crypto/ampl.svg";
import AnkrImage from "../assets/img/crypto/ankr.svg";
import AnkrbnbImage from "../assets/img/crypto/ankrbnb.svg";
import ApeImage from "../assets/img/crypto/ape.svg";
import AptImage from "../assets/img/crypto/apt.svg";
import ArbitrumImage from "../assets/img/crypto/arbitrum.svg";
import ArkmImage from "../assets/img/crypto/arkm.png";
import AstrImage from "../assets/img/crypto/astr.svg";
import AtomImage from "../assets/img/crypto/atom.svg";
import AvaxImage from "../assets/img/crypto/avax.svg";
import AvaxLPMImage from "../assets/img/crypto/avaxlpm.svg";
import AxlImage from "../assets/img/crypto/axl.svg";
import AxsImage from "../assets/img/crypto/axs.svg";
import BalImage from "../assets/img/crypto/bal.svg";
import BandImage from "../assets/img/crypto/band.svg";
import BaseImage from "../assets/img/crypto/base.svg";
import BatImage from "../assets/img/crypto/bat.svg";
import BchImage from "../assets/img/crypto/bch.svg";
import BcnaImage from "../assets/img/crypto/bcna.svg";
import BenqiImage from "../assets/img/crypto/benqi.svg";
import BethImage from "../assets/img/crypto/beth.svg";
import BitcoinImage from "../assets/img/crypto/bitcoin.svg";
import BitfinexImage from "../assets/img/crypto/bitfinex.svg";
import BitmexImage from "../assets/img/crypto/bitmex.svg";
import BlastImage from "../assets/img/crypto/blast.svg";
import BldImage from "../assets/img/crypto/bld.svg";
import BluefinImage from "../assets/img/crypto/bluefin.svg";
import BlurImage from "../assets/img/crypto/blur.svg";
import BnbImage from "../assets/img/crypto/bnb.svg";
import BobImage from "../assets/img/crypto/bob.svg";
import BoneImage from "../assets/img/crypto/bone.svg";
import BonkImage from "../assets/img/crypto/bonk.svg";
import BswImage from "../assets/img/crypto/bsw.svg";
import BtsgImage from "../assets/img/crypto/btsg.svg";
import BttImage from "../assets/img/crypto/btt.svg";
import BUSDImage from "../assets/img/crypto/busd.svg";
import BybitImage from "../assets/img/crypto/bybit.svg";
import CakeImage from "../assets/img/crypto/cake.svg";
import CbEthImage from "../assets/img/crypto/cbeth.svg";
import CeloImage from "../assets/img/crypto/celo.svg";
import CheqImage from "../assets/img/crypto/cheq.svg";
import ChrImage from "../assets/img/crypto/chr.svg";
import ChzImage from "../assets/img/crypto/chz.svg";
import CmdxImage from "../assets/img/crypto/cmdx.svg";
import CoinbaseImage from "../assets/img/crypto/coinbase.svg";
import CompoundImage from "../assets/img/crypto/compound.svg";
import CroImage from "../assets/img/crypto/cro.svg";
import CrvImage from "../assets/img/crypto/crv.svg";
import CrvUSDImage from "../assets/img/crypto/crvusd.svg";
import CudosImage from "../assets/img/crypto/cudos.svg";
import CvxImage from "../assets/img/crypto/cvx.svg";
import DaiImage from "../assets/img/crypto/dai.svg";
import DarcImage from "../assets/img/crypto/darc.svg";
import DecImage from "../assets/img/crypto/dec.svg";
import DegenImage from "../assets/img/crypto/degen.svg";
import DeribitImage from "../assets/img/crypto/deribit.svg";
import DesmosImage from "../assets/img/crypto/desmos.svg";
import DevImage from "../assets/img/crypto/dev.svg";
import DigImage from "../assets/img/crypto/dig.svg";
import DogeImage from "../assets/img/crypto/doge.svg";
import DolaImage from "../assets/img/crypto/dola.svg";
import DotImage from "../assets/img/crypto/dot.svg";
import DpiImage from "../assets/img/crypto/dpi.svg";
import DvpnImage from "../assets/img/crypto/dvpn.svg";
import DyDxImage from "../assets/img/crypto/dydx.svg";
import DYMImage from "../assets/img/crypto/dym.svg";
import EeurImage from "../assets/img/crypto/eeur.svg";
import EnjImage from "../assets/img/crypto/enj.svg";
import EnsImage from "../assets/img/crypto/ens.svg";
import EosImage from "../assets/img/crypto/eos.svg";
import EtcImage from "../assets/img/crypto/etc.svg";
import EthereumTokenImage from "../assets/img/crypto/ethereum.svg";
import EthFiImage from "../assets/img/crypto/etherfi-logo.svg";
import EuroImage from "../assets/img/crypto/eur.svg";
import EuraImage from "../assets/img/crypto/eura.svg";
import EureImage from "../assets/img/crypto/eure.svg";
import EurocImage from "../assets/img/crypto/euroc.svg";
import EursImage from "../assets/img/crypto/eurs.svg";
import EvmosImage from "../assets/img/crypto/evmos.svg";
import EzEthImage from "../assets/img/crypto/ezeth.svg";
import FantomImage from "../assets/img/crypto/fantom.svg";
import FdusdImage from "../assets/img/crypto/fdusd.svg";
import FeiImage from "../assets/img/crypto/fei.svg";
import FetImage from "../assets/img/crypto/fet.svg";
import FilImage from "../assets/img/crypto/fil.svg";
import FlokiImage from "../assets/img/crypto/floki.svg";
import FraxImage from "../assets/img/crypto/frax.svg";
import FtmLPMImage from "../assets/img/crypto/ftmlpm.svg";
import FxsImage from "../assets/img/crypto/fxs.svg";
import GalaImage from "../assets/img/crypto/gala.svg";
import GateIoImage from "../assets/img/crypto/gate-io.svg";
import GbpImage from "../assets/img/crypto/gbp.svg";
import GhoImage from "../assets/img/crypto/gho.svg";
import GhstImage from "../assets/img/crypto/ghst.svg";
import GkeyImage from "../assets/img/crypto/gkey.svg";
import GLPMImage from "../assets/img/crypto/glpm.svg";
import GltoImage from "../assets/img/crypto/glto.svg";
import GmtImage from "../assets/img/crypto/gmt.svg";
import GmxImage from "../assets/img/crypto/gmx.svg";
import GnoImage from "../assets/img/crypto/gno.svg";
import GnosisImage from "../assets/img/crypto/gnosis.svg";
import GraiImage from "../assets/img/crypto/grai.svg";
import GravImage from "../assets/img/crypto/grav.svg";
import GusdImage from "../assets/img/crypto/gusd.svg";
import HarmonyImage from "../assets/img/crypto/harmony.svg";
import HathorImage from "../assets/img/crypto/hathor-logo.svg";
import HbarImage from "../assets/img/crypto/hbar.svg";
import HundredFinanceImage from "../assets/img/crypto/hnd.svg";
import HuahuaImage from "../assets/img/crypto/huahua.svg";
import IcpImage from "../assets/img/crypto/icp.svg";
import ImxImage from "../assets/img/crypto/imx.svg";
import InjImage from "../assets/img/crypto/inj.svg";
import IonImage from "../assets/img/crypto/ion.svg";
import IosImage from "../assets/img/crypto/ios.svg";
import IovImage from "../assets/img/crypto/iov.svg";
import IrisImage from "../assets/img/crypto/iris.svg";
import IstImage from "../assets/img/crypto/ist.svg";
import JeurImage from "../assets/img/crypto/jeur.svg";
import DefiKingdomsImage from "../assets/img/crypto/jewel.svg";
import JTOImage from "../assets/img/crypto/jito.svg";
import JklImage from "../assets/img/crypto/jkl.svg";
import JunoImage from "../assets/img/crypto/juno.svg";
import JupImage from "../assets/img/crypto/jup.svg";
import KavaImage from "../assets/img/crypto/kava.svg";
import KncImage from "../assets/img/crypto/knc.svg";
import KrakenImage from "../assets/img/crypto/kraken.svg";
import KsmImage from "../assets/img/crypto/ksm.svg";
import KucoinImage from "../assets/img/crypto/kucoin.svg";
import KujiImage from "../assets/img/crypto/kuji.svg";
import KvtImage from "../assets/img/crypto/kvt.svg";
import LambImage from "../assets/img/crypto/lamb.svg";
import LdoImage from "../assets/img/crypto/ldo.svg";
import LikeImage from "../assets/img/crypto/like.svg";
import LineaImage from "../assets/img/crypto/linea.svg";
import LinkImage from "../assets/img/crypto/link.svg";
import LisUsdImage from "../assets/img/crypto/lisusd.svg";
import LooksImage from "../assets/img/crypto/looks.svg";
import LrcImage from "../assets/img/crypto/lrc.svg";
import LtcImage from "../assets/img/crypto/ltc.svg";
import LumImage from "../assets/img/crypto/lum.svg";
import LunaImage from "../assets/img/crypto/luna.svg";
import LusdImage from "../assets/img/crypto/lusd.svg";
import LvnImage from "../assets/img/crypto/lvn.svg";
import MakerImage from "../assets/img/crypto/maker.svg";
import MakerDaoImage from "../assets/img/crypto/makerdao.svg";
import ManaImage from "../assets/img/crypto/mana.svg";
import MantaImage from "../assets/img/crypto/manta.svg";
import MaticImage from "../assets/img/crypto/matic.svg";
import MaticLPMImage from "../assets/img/crypto/maticlpm.svg";
import MaticXImage from "../assets/img/crypto/maticx.svg";
import MaverickImage from "../assets/img/crypto/maverick.svg";
import MedImage from "../assets/img/crypto/med.svg";
import MetisImage from "../assets/img/crypto/metis.svg";
import MiladyImage from "../assets/img/crypto/milady.webp";
import MIMImage from "../assets/img/crypto/mim.svg";
import MaiImage from "../assets/img/crypto/mimatic.svg";
import MntlImage from "../assets/img/crypto/mntl.svg";
import MoonwellImage from "../assets/img/crypto/moonwell.svg";
import MoonriverImage from "../assets/img/crypto/movr.svg";
import MovrBWImage from "../assets/img/crypto/movr_bw.svg";
import MuteImage from "../assets/img/crypto/mute.svg";
import NetaImage from "../assets/img/crypto/neta.svg";
import NftPerpImage from "../assets/img/crypto/nftperp.svg";
import NgmImage from "../assets/img/crypto/ngm.svg";
import OdinImage from "../assets/img/crypto/odin.svg";
import OkxImage from "../assets/img/crypto/okx.svg";
import OnezImage from "../assets/img/crypto/onez.svg";
import OptimismImage from "../assets/img/crypto/op.svg";
import OpBnbImage from "../assets/img/crypto/opbnb.svg";
import OrdiImage from "../assets/img/crypto/ordi.svg";
import OsmoImage from "../assets/img/crypto/osmo.svg";
import PangolinImage from "../assets/img/crypto/pangolin.svg";
import PaxImage from "../assets/img/crypto/pax.svg";
import PepeImage from "../assets/img/crypto/pepe.svg";
import PinkImage from "../assets/img/crypto/pink.svg";
import PlanetImage from "../assets/img/crypto/planet.svg";
import PolygonImage from "../assets/img/crypto/polygon.svg";
import PpgImage from "../assets/img/crypto/ppg.webp";
import PstakeImage from "../assets/img/crypto/pstake.svg";
import PufEthImage from "../assets/img/crypto/pufeth.svg";
import PunksImage from "../assets/img/crypto/punks.webp";
import PythImage from "../assets/img/crypto/pyth.svg";
import PyUsdImage from "../assets/img/crypto/pyusd.svg";
import QIImage from "../assets/img/crypto/qi.svg";
import QntImage from "../assets/img/crypto/qnt.svg";
import QuintaImage from "../assets/img/crypto/quinta.svg";
import RadiantImage from "../assets/img/crypto/radiant.svg";
import RaiImage from "../assets/img/crypto/rai.svg";
import RdntImage from "../assets/img/crypto/rdnt.svg";
import RebusImage from "../assets/img/crypto/rebus.svg";
import RedStoneImage from "../assets/img/crypto/redstone.svg";
import RegenImage from "../assets/img/crypto/regen.svg";
import RenImage from "../assets/img/crypto/ren.svg";
import RenfilImage from "../assets/img/crypto/renfil.svg";
import RethImage from "../assets/img/crypto/reth.svg";
import RndrImage from "../assets/img/crypto/rndr.svg";
import RomeImage from "../assets/img/crypto/rome.svg";
import RowanImage from "../assets/img/crypto/rowan.svg";
import RplImage from "../assets/img/crypto/rpl.svg";
import RsEthImage from "../assets/img/crypto/rseth.svg";
import RsrImage from "../assets/img/crypto/rsr.svg";
import RuneImage from "../assets/img/crypto/rune.svg";
import SandboxImage from "../assets/img/crypto/sandbox.svg";
import SavaxImage from "../assets/img/crypto/savax.svg";
import ScrollImage from "../assets/img/crypto/scroll.svg";
import ScrtImage from "../assets/img/crypto/scrt.svg";
import sDaiImage from "../assets/img/crypto/sdai.svg";
import SeJunoImage from "../assets/img/crypto/seJUNO.svg";
import SeamImage from "../assets/img/crypto/seam.svg";
import SeamlessImage from "../assets/img/crypto/seamless.svg";
import SeiImage from "../assets/img/crypto/sei.svg";
import SfrxEthImage from "../assets/img/crypto/sfrxeth.svg";
import ShibImage from "../assets/img/crypto/shib.svg";
import SlisBnbImage from "../assets/img/crypto/slisbnb.svg";
import SnbnbImage from "../assets/img/crypto/snbnb.svg";
import SnxImage from "../assets/img/crypto/snx.svg";
import SolImage from "../assets/img/crypto/sol.svg";
import SolarImage from "../assets/img/crypto/solar.svg";
import SommImage from "../assets/img/crypto/somm.svg";
import StJunoImage from "../assets/img/crypto/stJUNO.svg";
import StOsmoImage from "../assets/img/crypto/stOSMO.svg";
import StStarsImage from "../assets/img/crypto/stSTARS.svg";
import StarsImage from "../assets/img/crypto/stars.svg";
import StAtomImage from "../assets/img/crypto/statom.svg";
import StethImage from "../assets/img/crypto/steth.svg";
import StgImage from "../assets/img/crypto/stg.svg";
import StkbnbImage from "../assets/img/crypto/stkbnb.svg";
import StMaticImage from "../assets/img/crypto/stmatic.svg";
import StoneImage from "../assets/img/crypto/stone.svg";
import StrdImage from "../assets/img/crypto/strd.svg";
import STRKImage from "../assets/img/crypto/strk.svg";
import StUsdtImage from "../assets/img/crypto/stusdt.svg";
import StxImage from "../assets/img/crypto/stx.svg";
import SuiImage from "../assets/img/crypto/sui.svg";
import SusdImage from "../assets/img/crypto/susd.svg";
import SwordImage from "../assets/img/crypto/sword.svg";
import SwthImage from "../assets/img/crypto/swth.svg";
import SxpImage from "../assets/img/crypto/sxp.svg";
import TapiocaImage from "../assets/img/crypto/tapioca.svg";
import TashiImage from "../assets/img/crypto/tashi.svg";
import TBtcImage from "../assets/img/crypto/tbtc.svg";
import TerraImage from "../assets/img/crypto/terra.svg";
import TgdImage from "../assets/img/crypto/tgd.svg";
import TheGraphImage from "../assets/img/crypto/the-graph.svg";
import ThetaImage from "../assets/img/crypto/theta.svg";
import TiaImage from "../assets/img/crypto/tia.svg";
import TonImage from "../assets/img/crypto/ton.svg";
import ToriImage from "../assets/img/crypto/tori.svg";
import TrxImage from "../assets/img/crypto/trx.svg";
import TusdImage from "../assets/img/crypto/tusd.svg";
import TwtImage from "../assets/img/crypto/twt.svg";
import UmaImage from "../assets/img/crypto/uma.svg";
import UmeeImage from "../assets/img/crypto/umee.svg";
import UniImage from "../assets/img/crypto/uni.svg";
import UniswapImage from "../assets/img/crypto/uniswap.svg";
import UsdbImage from "../assets/img/crypto/usdb.svg";
import UsdbcImage from "../assets/img/crypto/usdbc.svg";
import UsdcImage from "../assets/img/crypto/usdc.svg";
import UsddImage from "../assets/img/crypto/usdd.svg";
import UsdoImage from "../assets/img/crypto/usdo.svg";
import UsdpImage from "../assets/img/crypto/usdp.svg";
import UsdtImage from "../assets/img/crypto/usdt.svg";
import UstcImage from "../assets/img/crypto/ustc.svg";
import UzdImage from "../assets/img/crypto/uzd.svg";
import VaiImage from "../assets/img/crypto/vai.svg";
import VcImage from "../assets/img/crypto/vc.svg";
import VdlImage from "../assets/img/crypto/vdl.svg";
import VenusImage from "../assets/img/crypto/venus.svg";
import WavesImage from "../assets/img/crypto/waves.svg";
import WbethImage from "../assets/img/crypto/wbeth.svg";
import WbnbImage from "../assets/img/crypto/wbnb.svg";
import WbtcImage from "../assets/img/crypto/wbtc.svg";
import WeEthImage from "../assets/img/crypto/weeth.svg";
import WifImage from "../assets/img/crypto/wif.svg";
import WinImage from "../assets/img/crypto/win.svg";
import WldImage from "../assets/img/crypto/wld.svg";
import WoneImage from "../assets/img/crypto/wone.svg";
import WooImage from "../assets/img/crypto/woo.svg";
import WstEthImage from "../assets/img/crypto/wsteth.svg";
import WuSdmImage from "../assets/img/crypto/wusdm.svg";
import XagImage from "../assets/img/crypto/xag.svg";
import XaiImage from "../assets/img/crypto/xai.svg";
import XauImage from "../assets/img/crypto/xau.svg";
import XdaiImage from "../assets/img/crypto/xdai.svg";
import XkiImage from "../assets/img/crypto/xki.svg";
import XlmImage from "../assets/img/crypto/xlm.svg";
import XmrImage from "../assets/img/crypto/xmr.svg";
import XprtImage from "../assets/img/crypto/xprt.svg";
import XrpImage from "../assets/img/crypto/xrp.svg";
import XsushiImage from "../assets/img/crypto/xsushi.svg";
import XvsImage from "../assets/img/crypto/xvs.svg";
import YfiImage from "../assets/img/crypto/yfi.svg";
import ZecImage from "../assets/img/crypto/zec.svg";
import ZeroLendImage from "../assets/img/crypto/zerolend.svg";
import ZilImage from "../assets/img/crypto/zil.svg";
import ZkSyncImage from "../assets/img/crypto/zksync.svg";
import ZrxImage from "../assets/img/crypto/zrx.svg";
import AvalancheImage from "../assets/img/icons/avalanche.svg";
import CardanoImage from "../assets/img/icons/cardano.svg";
import EthereumImage from "../assets/img/icons/ethereum-logo.svg";
import NearImage from "../assets/img/icons/near.svg";
import SolanaImage from "../assets/img/icons/solana.svg";
import SushiImage from "../assets/img/icons/sushi.svg";
import WETHImage from "../assets/img/icons/weth_logo.svg";
import SfraxImage from "../assets/img/crypto/sFrax.svg";
import OsethImage from "../assets/img/crypto/oseth.svg";
import UniethImage from "../assets/img/crypto/unieth.svg";
import AeroImage from "../assets/img/crypto/aero.svg";
import BabyDogeImage from "../assets/img/crypto/babydoge.svg";
import PTweETHDEC24Image from "../assets/img/crypto/pt-weeth-26dec2024.svg";
import BrettImage from "../assets/img/crypto/brett.svg";
import PuppetsImage from "../assets/img/crypto/puppets.webp";
import MonkeImage from "../assets/img/crypto/monke.webp";
import RemilioImage from "../assets/img/crypto/remilio.webp";
import WImage from "../assets/img/crypto/w.svg";
import SolvBTCImage from "../assets/img/crypto/solvbtc-m.svg";
import MBTCImage from "../assets/img/crypto/mbtc.svg";
import MotherImage from "../assets/img/crypto/mother.svg";
import ETHxImage from "../assets/img/crypto/ethx.svg";
import MOGImage from "../assets/img/crypto/mog.webp";
import OstiumImage from "../assets/img/crypto/ostium.svg";
import BodenImage from "../assets/img/crypto/boden.webp";
import TrempImage from "../assets/img/crypto/tremp.webp";
import ZroImage from "../assets/img/crypto/zro.svg";
import ZkImage from "../assets/img/crypto/zk.svg";
import JupiterImage from "../assets/img/crypto/jupiter.svg";
import GMEthImage from "../assets/img/crypto/gmeth.svg";
import GMBtcImage from "../assets/img/crypto/gmbtc.svg";
import TaoImage from "../assets/img/crypto/tao.svg";
import IoImage from "../assets/img/crypto/io.svg";
import SatsImage from "../assets/img/crypto/sats.svg";
import SafeImage from "../assets/img/crypto/safe.svg";
import TlosImage from "../assets/img/crypto/tlos.svg";
import OndoImage from "../assets/img/crypto/ondo.svg";
import KasImage from "../assets/img/crypto/kas.svg";
import TaikoImage from "../assets/img/crypto/taiko.svg";
import NotImage from "../assets/img/crypto/not.svg";
import EnaImage from "../assets/img/crypto/ena.svg";
import ArImage from "../assets/img/crypto/ar.svg";
import AltImage from "../assets/img/crypto/alt.svg";
import BeamImage from "../assets/img/crypto/beam.svg";
import BomeImage from "../assets/img/crypto/bome.svg";
import CoreImage from "../assets/img/crypto/core.svg";
import EgldImage from "../assets/img/crypto/egld.svg";
import JasmyImage from "../assets/img/crypto/jasmy.svg";
import MewImage from "../assets/img/crypto/mew.svg";
import MntImage from "../assets/img/crypto/mnt.svg";
import NeoImage from "../assets/img/crypto/neo.svg";
import PendleImage from "../assets/img/crypto/pendle.svg";
import PeopleImage from "../assets/img/crypto/people.svg";
import PixelImage from "../assets/img/crypto/pixel.svg";
import PopcatImage from "../assets/img/crypto/popcat.svg";
import PrimeImage from "../assets/img/crypto/prime.svg";
import TrbImage from "../assets/img/crypto/trb.svg";
import TurboImage from "../assets/img/crypto/turbo.svg";
import XtzImage from "../assets/img/crypto/xtz.svg";
import ZenImage from "../assets/img/crypto/zen.svg";
import BicoImage from "../assets/img/crypto/bico.svg";
import OMImage from "../assets/img/crypto/om.svg";
import JoeImage from "../assets/img/crypto/joe.svg";
import CoqInuImage from "../assets/img/crypto/coqinu.png";
import CJImage from "../assets/img/real-world-assets/cj.svg";
import CLImage from "../assets/img/real-world-assets/cl.svg";
import HGImage from "../assets/img/real-world-assets/hg.svg";
import HOGSImage from "../assets/img/real-world-assets/hogs.svg";
import HSIImage from "../assets/img/real-world-assets/hsi.svg";
import JPYImage from "../assets/img/real-world-assets/jpy.svg";
import LCOImage from "../assets/img/real-world-assets/lco.svg";
import LEImage from "../assets/img/real-world-assets/le.svg";
import NGImage from "../assets/img/real-world-assets/ng.svg";
import NIKImage from "../assets/img/real-world-assets/nik.svg";
import XPDImage from "../assets/img/real-world-assets/xpd.svg";
import XPTImage from "../assets/img/real-world-assets/xpt.svg";
import ZSImage from "../assets/img/real-world-assets/zs.svg";
import DmailImage from "../assets/img/crypto/dmail.svg";
import YvUSDCImage from "../assets/img/crypto/yvusdc.svg";
import YvwETHImage from "../assets/img/crypto/yvweth.svg";
import YvDAIImage from "../assets/img/crypto/yvdai.svg";
import StecrvImage from "../assets/img/crypto/stecrv.svg";
import SusdeImage from "../assets/img/crypto/susde.svg";
import Stkcvxgusd3crvImage from "../assets/img/crypto/stkcvxgusd3crv.svg";
import StkcvxcrvcvxethImage from "../assets/img/crypto/stkcvxcrvcvxeth.svg";
import Cvxgusd3crvImage from "../assets/img/crypto/cvxgusd3crv.svg";
import Gusd3crvImage from "../assets/img/crypto/gusd3crv.svg";
import StkcvxstecrvImage from "../assets/img/crypto/stkcvxstecrv.svg";
import RswethImage from "../assets/img/crypto/rsweth.svg";
import StkcvxcrvfraxImage from "../assets/img/crypto/stkcvxcrvfrax.svg";
import Crvplain3andsusdImage from "../assets/img/crypto/crvplain3andsusd.svg";
import Stkcvx3crvImage from "../assets/img/crypto/stkcvx3crv.svg";
import CvxstecrvImage from "../assets/img/crypto/cvxstecrv.svg";
import Stkcvxcrvplain3andsusdImage from "../assets/img/crypto/stkcvxcrvplain3andsusd.svg";
import Stkcvxfrax3crvImage from "../assets/img/crypto/stkcvxfrax3crv.svg";
import Frax3crvImage from "../assets/img/crypto/frax3crv.svg";
import StkcvxcrvusdusdtImage from "../assets/img/crypto/stkcvxcrvusdusdt.svg";
import Stkcvxlusd3crvImage from "../assets/img/crypto/stkcvxlusd3crv.svg";
import YvCurveStethImage from "../assets/img/crypto/yvcurve_steth.svg";
import MaviaImage from "../assets/img/crypto/mavia.svg";
import UsdeImage from "../assets/img/crypto/usde.svg";
import RenderImage from "../assets/img/crypto/render.svg";
import MichiImage from "../assets/img/crypto/michi.svg";
import VrtxImage from "../assets/img/crypto/vrtx.svg";
import GearboxImage from "../assets/img/crypto/gearbox.svg";
import AuroraImage from "../assets/img/crypto/aurora.svg";
import FoxyImage from "../assets/img/crypto/foxy.webp";
import PaxgImage from "../assets/img/crypto/paxg.svg";
import DriftImage from "../assets/img/crypto/drift.svg";
import GMEImage from "../assets/img/crypto/gme.svg";
import weETHs from "../assets/img/crypto/weETHs.svg";
import EurcImage from "../assets/img/crypto/eurc.svg";
import RetardioImage from "../assets/img/crypto/retardio.svg";
import EigenDAImage from "../assets/img/crypto/EigenDA.svg";
import EoracleImage from "../assets/img/crypto/Eoracle.svg";
import HyperlaneImage from "../assets/img/crypto/Hyperlane.svg";
import OpenLayerImage from "../assets/img/crypto/OpenLayer.svg";
import XterioMachImage from "../assets/img/crypto/xterio-mach.svg";
import WitnessChainImage from "../assets/img/crypto/witness.svg";
import OmniNetworkImage from "../assets/img/crypto/Omni.svg";
import LagrangeImage from "../assets/img/crypto/lagrange.svg";
import GMMachImage from "../assets/img/crypto/gm-mach.svg";
import DodoMachImage from "../assets/img/crypto/dodochain-mach.svg";
import BrevisImage from "../assets/img/crypto/brevis.svg";
import AutomataImage from "../assets/img/crypto/automata.svg";
import ArpaImage from "../assets/img/crypto/ARPA.svg";
import CyberMachImage from "../assets/img/crypto/cyber-mach.svg";
import AltLayerImage from "../assets/img/crypto/altlayer.svg";
import RenzoImage from "../assets/img/crypto/renzo.svg";
import KelpImage from "../assets/img/crypto/kelp.svg";
import PufferImage from "../assets/img/crypto/puffer.svg";
import EtherfiImage from "../assets/img/crypto/etherfi.svg";
import UsdyImage from "../assets/img/crypto/usdy.svg";
import PolImage from "../assets/img/crypto/pol.webp";
import HeliumImage from "../assets/img/crypto/hnt.svg";
import NeiroImage from "../assets/img/crypto/Neiro.svg";
import CbBtcImage from "../assets/img/crypto/cbbtc.svg";
import EBtc from "../assets/img/crypto/ebtc.svg";
import LBtc from "../assets/img/crypto/lbtc.svg";
import TryImage from "../assets/img/crypto/try.svg";
import SundogImage from "../assets/img/crypto/sundog.svg";
import SolvBTC from "../assets/img/crypto/solvbtc.svg";
import UsdsImage from "../assets/img/crypto/usds.svg";
import EigenImage from "../assets/img/crypto/eigen.svg";
import WsuperOethImage from "../assets/img/crypto/wsuperOETH.svg";
import GlvImage from "../assets/img/crypto/glv.svg";
import AUSD from "../assets/img/crypto/AUSD_64px.svg";
import TrumpWinImage from "../assets/img/crypto/trumpwin.png";
import CetusImage from "../assets/img/crypto/cetus.png";
import ZbcnImage from "../assets/img/crypto/zbcn.svg";
import SendImage from "../assets/img/crypto/send.svg";
import OntImage from "../assets/img/crypto/ont.svg";
import PerpImage from "../assets/img/crypto/perp.svg";
import QtumImage from "../assets/img/crypto/qtum.svg";
import MemeImage from "../assets/img/crypto/meme.svg";
import PumpBtcImage from "../assets/img/crypto/pumpbtc.svg";
import ClvImage from "../assets/img/crypto/clv.svg";
import IdImage from "../assets/img/crypto/id.svg";
import LuceImage from "../assets/img/crypto/luce.svg";
import MoodengImage from "../assets/img/crypto/moodeng.svg";
import OrderImage from "../assets/img/crypto/order.svg";
import PnutImage from "../assets/img/crypto/pnut.svg";
import RayImage from "../assets/img/crypto/ray.svg";
import XyoImage from "../assets/img/crypto/xyo.svg";
import DeepImage from "../assets/img/crypto/deep.svg";
import GrassImage from "../assets/img/crypto/grass.svg";
import MadImage from "../assets/img/crypto/mad.webp";
import SklImage from "../assets/img/crypto/skale-logo.svg";
import AthImage from "../assets/img/crypto/aethir.svg";
import HawkTuahImage from "../assets/img/crypto/hawktuah.webp";
import PirateImage from "../assets/img/crypto/pirate-nation.png";
import PowscheImage from "../assets/img/crypto/powsche.webp";
import BitsCrunchImage from "../assets/img/crypto/bitsCrunch.png";
import VetImage from "../assets/img/crypto/vechain-vet-logo.svg";
import GImage from "../assets/img/crypto/g.svg";
import FartCoinImage from "../assets/img/crypto/fartcoin.svg";
import MocaImage from "../assets/img/crypto/moca.svg";
import MoveImage from "../assets/img/crypto/move.svg";
import EdgeImage from "../assets/img/crypto/edge.svg";
import UsualImage from "../assets/img/crypto/usual.svg";
import SpxImage from "../assets/img/crypto/spx.svg";
import ZircuitImage from "../assets/img/icons/zircuit.svg";
import SparkImage from "../assets/img/icons/spark-fi-icon.svg";
import SwellImage from "../assets/img/icons/swell.svg";
import MitosisImage from "../assets/img/icons/mitosis_logo_symbol_basic.svg";
import KarakImage from "../assets/img/icons/karak.svg";
import FluidImage from "../assets/img/icons/Symbol-Fluid.svg";
import PtSolvBtcImage from "../assets/img/crypto/ptSolvBtc.svg";
import PtSusdeImage from "../assets/img/crypto/pt-susde.svg";
import PtUSDeImage from "../assets/img/crypto/pt-usde-27mar2025.svg";
import EEigenImage from "../assets/img/crypto/eeigen-icon.svg";
import LiquidEthImage from "../assets/img/crypto/liquid-eth-icon.svg";
import WeEthKImage from "../assets/img/crypto/weethk-icon.png";
import EUsdImage from "../assets/img/crypto/eusd-icon.svg";
import MEthImage from "../assets/img/crypto/meth.webp";
import TrumpImage from "../assets/img/crypto/trump.svg";
import MelaniaImage from "../assets/img/crypto/melania.svg";
import MerkleImage from "../assets/img/merkle-trade.svg";
import AriesImage from "../assets/img/aries.svg";
import CellanaImage from "../assets/img/cellana.svg";
import SuperpositionImage from "../assets/img/superposition.svg";
import EchelonImage from "../assets/img/echelon.avif";
import ThalaImage from "../assets/img/thala.svg";
import JouleImage from "../assets/img/joule.svg";
import AmnisImage from "../assets/img/Amnis.svg";
import MesoImage from "../assets/img/meso.svg";
import Usdt0Image from "../assets/img/crypto/usdt0.svg";
import AnimaImage from "../assets/img/crypto/anima.svg";
import Ai16zImage from "../assets/img/crypto/ai16z.svg";
import TheImage from "../assets/img/crypto/the.svg";
import sUSDSImage from "../assets/img/crypto/susds.svg";
import BeraImage from "../assets/img/crypto/BERA.svg";
import yvUSDSImage from "../assets/img/crypto/yvusds.svg";
import yvUSDC1Image from "../assets/img/crypto/yvusdc-1.svg";
import yvUSDT1Image from "../assets/img/crypto/yvusdt-1.svg";
import yvWETH1Image from "../assets/img/crypto/yvweth-1.svg";
import PenguImage from "../assets/img/crypto/pengu.svg";
import VirtualImage from "../assets/img/crypto/virtual.svg";

export const getIconSrc: (iconName: string) => string | null = (iconName) => {
  const loweCaseIconName = iconName?.toLowerCase();
  switch (loweCaseIconName) {
    case "btc":
    case "btc.b":
    case "btcb":
      return BitcoinImage;
    case "btc-logo":
      return BitcoinLogoImage;
    case "dydx":
      return DyDxImage;
    case "polygon":
    case "polygon_mumbai":
      return PolygonImage;
    case "metis":
      return MetisImage;
    case "mfam":
      return MoonwellImage;
    case "moonwell":
      return MoonwellImage;
    case "sushi":
    case "sushiswap":
      return SushiImage;
    case "wbtc":
    case "wbtc.e":
      return WbtcImage;
    case "qibtc":
      return BitcoinImage;
    case "weth.e":
    case "qieth":
    case "twethm":
    case "wethm":
    case "weth":
    case "variabledebtethweth":
      return WETHImage;
    case "wsteth":
    case "aethwsteth":
      return WstEthImage;
    case "cbeth":
      return CbEthImage;
    case "xrp":
      return XrpImage;
    case "xvs":
      return XvsImage;
    case "trx":
    case "trxold":
      return TrxImage;
    case "wbeth":
      return WbethImage;
    case "sxp":
      return SxpImage;
    case "cake":
      return CakeImage;
    case "beth":
      return BethImage;
    case "benqi-logo":
      return BenqiImage;
    case "benqi":
      return QIImage;
    case "qiqi":
      return QIImage;
    case "qi":
      return QIImage;
    case "pink":
      return PinkImage;
    case "solar":
      return SolarImage;
    case "hnd":
      return HundredFinanceImage;
    case "mim":
      return MIMImage;
    case "pangolin":
      return PangolinImage;
    case "png":
      return PangolinImage;
    case "rome":
      return RomeImage;
    case "frax":
    case "fraxlendv1 - crv/frax":
      return FraxImage;
    case "sfrxeth":
    case "sfrxeth-v1":
    case "sfrxeth-v2":
      return SfrxEthImage;
    case "wftm":
    case "tftm":
    case "tftmm":
    case "ftm":
    case "ftmm":
    case "fantom":
    case "fantom_testnet":
      return FantomImage;
    case "harmony":
      return HarmonyImage;
    case "ksm":
      return KsmImage;
    case "wbnb":
      return WbnbImage;
    case "bnb":
    case "binance":
    case "binance-futures":
      return BnbImage;
    case "bnb.bsc":
      return BnbImage;
    case "busd":
      return BUSDImage;
    case "movr_bw":
      return MovrBWImage;
    case "wmovr":
    case "movr":
    case "moonriver":
      return MoonriverImage;
    case "near":
      return NearImage;
    case "arbitrum":
    case "arb":
    case "arbitrum_goerli":
    case "arbitrum_sepolia":
      return ArbitrumImage;
    case "solana":
      return SolanaImage;
    case "avalanche":
    case "avalanche_fuji":
      return AvalancheImage;
    case "huahua":
      return HuahuaImage;
    case "bld":
      return BldImage;
    case "tgd":
      return TgdImage;
    case "dig":
      return DigImage;
    case "umee":
      return UmeeImage;
    case "mntl":
      return MntlImage;
    case "swth":
      return SwthImage;
    case "statom":
      return StAtomImage;
    case "strd":
      return StrdImage;
    case "axl":
      return AxlImage;
    case "dev":
      return DevImage;
    case "somm":
      return SommImage;
    case "darc":
      return DarcImage;
    case "desmos":
    case "dsm":
      return DesmosImage;
    case "band":
      return BandImage;
    case "vdl":
      return VdlImage;
    case "lum":
      return LumImage;
    case "cheq":
      return CheqImage;
    case "cmdx":
      return CmdxImage;
    case "med":
      return MedImage;
    case "xki":
      return XkiImage;
    case "btsg":
      return BtsgImage;
    case "bcna":
      return BcnaImage;
    case "eeur":
      return EeurImage;
    case "ngm":
      return NgmImage;
    case "iov":
      return IovImage;
    case "dvpn":
      return DvpnImage;
    case "regen":
      return RegenImage;
    case "pstake":
      return PstakeImage;
    case "xprt":
      return XprtImage;
    case "stars":
      return StarsImage;
    case "evmos":
      return EvmosImage;
    case "ios":
      return IosImage;
    case "juno":
      return JunoImage;
    case "scrt":
      return ScrtImage;
    case "akt":
      return AktImage;
    case "like":
      return LikeImage;
    case "inj":
      return InjImage;
    case "fet":
      return FetImage;
    case "iris":
      return IrisImage;
    case "grav":
      return GravImage;
    case "kuji":
      return KujiImage;
    case "ion":
      return IonImage;
    case "dec":
      return DecImage;
    case "stosmo":
      return StOsmoImage;
    case "jkl":
      return JklImage;
    case "tori":
      return ToriImage;
    case "ist":
      return IstImage;
    case "rebus":
      return RebusImage;
    case "cudos":
      return CudosImage;
    case "ustc":
      return UstcImage;
    case "ststars":
      return StStarsImage;
    case "stjuno":
      return StJunoImage;
    case "usdt.grv":
      return UsdtGrvImage;
    case "usdc.grv":
      return UsdcGrvImage;
    case "rowan":
      return RowanImage;
    case "neta":
      return NetaImage;
    case "glto":
      return GltoImage;
    case "odin":
      return OdinImage;
    case "lvn":
      return LvnImage;
    case "lamb":
      return LambImage;
    case "sejuno":
      return SeJunoImage;
    case "gkey":
      return GkeyImage;
    case "veth":
      return EthereumImage;
    case "eth":
      return EthereumImage;
    case "ethereum":
    case "goerli":
    case "bitcoin lrts market":
      return EthereumImage;
    case "bitcoin":
      return BitcoinImage;
    case "ethereum-token":
      return EthereumTokenImage;
    case "cardano":
      return CardanoImage;
    case "ada":
      return CardanoImage;
    case "sol":
      return SolImage;
    case "aave":
      return AaveImage;
    case "qiavax":
      return AvaxImage;
    case "tavax":
    case "wavax":
    case "avax":
    case "tavaxm":
    case "avaxm":
      return AvaxImage;
    case "savax":
      return SavaxImage;
    case "celo":
      return CeloImage;
    case "compound":
      return CompoundImage;
    case "comp":
      return CompoundImage;
    case "qidai":
    case "dai":
    case "dai.e":
    case "m.dai":
      return DaiImage;
    case "sdai":
      return sDaiImage;
    case "link":
    case "chainlink":
    case "chainlink-prod":
    case "link.e":
      return LinkImage;
    case "luna":
      return LunaImage;
    case "lunc":
      return LunaImage;
    case "tmatic":
    case "tmaticm":
    case "maticm":
    case "imatic":
    case "wmatic":
    case "matic":
    case "wpol":
      return MaticImage;
    case "maticx":
      return MaticXImage;
    case "stmatic":
      return StMaticImage;
    case "mkr":
    case "maker":
      return MakerImage;
    case "qnt":
      return QntImage;
    case "bone":
      return BoneImage;
    case "euroc":
      return EurocImage;
    case "ldo":
      return LdoImage;
    case "looks":
      return LooksImage;
    case "rpl":
      return RplImage;
    case "gho":
      return GhoImage;
    case "grt":
      return TheGraphImage;
    case "uniswap":
    case "uniswapv3":
      return UniswapImage;
    case "hathor":
      return HathorImage;
    case "uni":
      return UniImage;
    case "optimism":
    case "op":
      return OptimismImage;
    case "qiusdt":
    case "fusdt":
    case "usdt":
    case "usdt.e":
    case "m.usdt":
    case "aethusdt":
      return UsdtImage;
    case "stusdt":
      return StUsdtImage;
    case "qiusdc":
    case "usd":
    case "usdc":
    case "usdc.e":
    case "m.usdc":
      return UsdcImage;
    case "susd":
      return SusdImage;
    case "algorand":
      return AlgorandImage;
    case "algo":
      return AlgorandImage;
    case "zrx":
      return ZrxImage;
    case "bat":
      return BatImage;
    case "dot":
      return DotImage;
    case "crv":
    case "curve":
      return CrvImage;
    case "crvusd":
      return CrvUSDImage;
    case "ust":
      return TerraImage;
    case "jewel":
      return DefiKingdomsImage;
    case "doge":
      return DogeImage;
    case "dogecoin":
      return DogeImage;
    case "ltc":
      return LtcImage;
    case "litecoin":
      return LtcImage;
    case "bch":
      return BchImage;
    case "bitcoin-cash":
      return BchImage;
    case "atom":
      return AtomImage;
    case "cosmos":
      return AtomImage;
    case "stellar":
      return XlmImage;
    case "xlm":
      return XlmImage;
    case "xmr":
      return XmrImage;
    case "monero":
      return XmrImage;
    case "etc":
      return EtcImage;
    case "ethereum-classic":
      return EtcImage;
    case "icp":
      return IcpImage;
    case "internet-computer":
      return IcpImage;
    case "fil":
      return FilImage;
    case "filecoin":
      return FilImage;
    case "eos":
      return EosImage;
    case "rune":
      return RuneImage;
    case "thorchain":
      return RuneImage;
    case "zec":
      return ZecImage;
    case "zcash":
      return ZecImage;
    case "snx":
      return SnxImage;
    case "synthetix":
    case "synthetix network":
      return SnxImage;
    case "enj":
      return EnjImage;
    case "enjin":
      return EnjImage;
    case "yfi":
      return YfiImage;
    case "yearn":
      return YfiImage;
    case "inch":
    case "1inch":
      return OneInchImage;
    case "uma":
      return UmaImage;
    case "shib":
      return ShibImage;
    case "axs":
      return AxsImage;
    case "ape":
      return ApeImage;
    case "gmt":
      return GmtImage;
    case "waves":
      return WavesImage;
    case "gusd":
      return GusdImage;
    case "steth":
      return StethImage;
    case "sand":
      return SandboxImage;
    case "mana":
      return ManaImage;
    case "gala":
      return GalaImage;
    case "zil":
      return ZilImage;
    case "rsr":
      return RsrImage;
    case "lrc":
      return LrcImage;
    case "kava":
      return KavaImage;
    case "ens":
      return EnsImage;
    case "theta":
      return ThetaImage;
    case "chz":
      return ChzImage;
    case "cro":
      return CroImage;
    case "chr":
      return ChrImage;
    case "ghst":
      return GhstImage;
    case "jeur":
      return JeurImage;
    case "dpi":
      return DpiImage;
    case "bal":
    case "balancer":
      return BalImage;
    case "ageur":
      return AgeurImage;
    case "eurs":
      return EursImage;
    case "wone":
    case "one":
      return WoneImage;
    case "mimatic":
    case "mai":
      return MaiImage;
    case "makerdao":
      return MakerDaoImage;
    case "osmo":
      return OsmoImage;
    case "gmx":
      return GmxImage;
    case "cvx":
      return CvxImage;
    case "fei":
      return FeiImage;
    case "knc":
      return KncImage;
    case "lusd":
      return LusdImage;
    case "pax":
      return PaxImage;
    case "rai":
      return RaiImage;
    case "ren":
      return RenImage;
    case "renfil":
      return RenfilImage;
    case "tusd":
    case "tusdold":
      return TusdImage;
    case "usdp":
      return UsdpImage;
    case "xsushi":
      return XsushiImage;
    case "ampl":
      return AmplImage;
    case "reth":
      return RethImage;
    case "bob":
      return BobImage;
    case "usdo":
      return UsdoImage;
    case "considered-finance":
      return ConsideredFinanceImage;
    case "rdnt":
      return RdntImage;
    case "radiant":
      return RadiantImage;
    case "tftmlpm":
    case "ftmlpm":
      return FtmLPMImage;
    case "tmaticlpm":
    case "maticlpm":
      return MaticLPMImage;
    case "tglpm":
    case "glpm":
      return GLPMImage;
    case "tavaxlpm":
    case "avaxlpm":
      return AvaxLPMImage;
    case "venus":
      return VenusImage;
    case "tapioca":
      return TapiocaImage;
    case "btt":
      return BttImage;
    case "usdd":
      return UsddImage;
    case "win":
      return WinImage;
    case "ankrbnb":
      return AnkrbnbImage;
    case "stkbnb":
      return StkbnbImage;
    case "floki":
      return FlokiImage;
    case "ankr":
      return AnkrImage;
    case "bsw":
      return BswImage;
    case "alpaca":
      return AlpacaImage;
    case "hay":
      return HayImage;
    case "raca":
      return RacaImage;
    case "bnbx":
      return BnbxImage;
    case "xai":
      return XaiImage;
    case "uzd":
      return UzdImage;
    case "dola":
      return DolaImage;
    case "quinta":
      return QuintaImage;
    case "3crv":
      return ThreeCrvImage;
    case "kvt":
      return KvtImage;
    case "tbtc":
      return TBtcImage;
    case "usdbc":
      return UsdbcImage;
    case "base":
      return BaseImage;
    case "stg":
      return StgImage;
    case "fxs":
      return FxsImage;
    case "snbnb":
      return SnbnbImage;
    case "twt":
      return TwtImage;
    case "tashi":
      return TashiImage;
    case "seamless":
      return SeamlessImage;
    case "zero":
    case "zerolend":
      return ZeroLendImage;
    case "gnosis":
      return GnosisImage;
    case "gno":
      return GnoImage;
    case "xdai":
    case "wxdai":
      return XdaiImage;
    case "eure":
      return EureImage;
    case "planet":
      return PlanetImage;
    case "aptos":
    case "apt":
      return AptImage;
    case "blur":
      return BlurImage;
    case "pepe":
      return PepeImage;
    case "sei":
      return SeiImage;
    case "sui":
      return SuiImage;
    case "wld":
      return WldImage;
    case "maverick":
      return MaverickImage;
    case "tia":
      return TiaImage;
    case "bluefin":
      return BluefinImage;
    case "fdusd":
      return FdusdImage;
    case "pyth":
      return PythImage;
    case "mute":
      return MuteImage;
    case "sword":
      return SwordImage;
    case "vc":
      return VcImage;
    case "onez":
      return OnezImage;
    case "pyusd":
      return PyUsdImage;
    case "jup":
      return JupImage;
    case "zksync":
      return ZkSyncImage;
    case "jto":
      return JTOImage;
    case "ordi":
      return OrdiImage;
    case "lisusd":
      return LisUsdImage;
    case "slisbnb":
      return SlisBnbImage;
    case "manta":
      return MantaImage;
    case "stone":
      return StoneImage;
    case "wusdm":
      return WuSdmImage;
    case "dym":
      return DYMImage;
    case "strk":
      return STRKImage;
    case "agix":
      return AgixImage;
    case "bonk":
      return BonkImage;
    case "woo":
      return WooImage;
    case "scroll":
    case "scr":
      return ScrollImage;
    case "rndr":
      return RndrImage;
    case "stx":
      return StxImage;
    case "imx":
      return ImxImage;
    case "hbar":
      return HbarImage;
    case "blast":
      return BlastImage;
    case "usdb":
      return UsdbImage;
    case "linea":
      return LineaImage;
    case "grai":
      return GraiImage;
    case "vai":
      return VaiImage;
    case "eur":
      return EuroImage;
    case "gbp":
      return GbpImage;
    case "xau":
      return XauImage;
    case "xag":
      return XagImage;
    case "eura":
      return EuraImage;
    case "ezeth":
      return EzEthImage;
    case "pufeth":
      return PufEthImage;
    case "rseth":
      return RsEthImage;
    case "wrseth":
      return RsEthImage;
    case "eeth":
    case "weeth":
    case "aethweeth":
      return WeEthImage;
    case "bitmex":
      return BitmexImage;
    case "bitfinex":
      return BitfinexImage;
    case "kraken":
    case "cryptofacilities":
      return KrakenImage;
    case "coinbase":
      return CoinbaseImage;
    case "okex":
    case "okex-swap":
      return OkxImage;
    case "ppg":
    case "ppg_blast":
      return PpgImage;
    case "punks":
    case "punks_blast":
      return PunksImage;
    case "milady":
    case "milady_blast":
      return MiladyImage;
    case "aevo":
      return AevoImage;
    case "arkm":
      return ArkmImage;
    case "astr":
      return AstrImage;
    case "deribit":
      return DeribitImage;
    case "nftperp":
      return NftPerpImage;
    case "opbnb":
      return OpBnbImage;
    case "bybit-spot":
    case "bybit":
      return BybitImage;
    case "gate-io":
    case "gate-io-futures":
      return GateIoImage;
    case "kucoin":
      return KucoinImage;
    case "redstone":
      return RedStoneImage;
    case "degen":
      return DegenImage;
    case "seam":
      return SeamImage;
    case "wif":
    case "$wif":
      return WifImage;
    case "ethfi":
    case "sethfi":
      return EthFiImage;
    case "ton":
      return TonImage;
    case "oseth":
    case "oseth.org":
      return OsethImage;
    case "unieth":
      return UniethImage;
    case "sfrax":
      return SfraxImage;
    case "aero":
      return AeroImage;
    case "babydoge":
      return BabyDogeImage;
    case "pt-weeth-26dec2024":
      return PTweETHDEC24Image;
    case "brett":
      return BrettImage;
    case "puppets":
    case "puppets_wbtc_blast":
      return PuppetsImage;
    case "monke":
    case "monke_wbtc_blast":
      return MonkeImage;
    case "w":
    case "wormhole":
      return WImage;
    case "SolvBTC.m":
      return SolvBTCImage;
    case "M-BTC":
      return MBTCImage;
    case "remilio":
    case "remilio_blast":
      return RemilioImage;
    case "mother":
      return MotherImage;
    case "ethx":
      return ETHxImage;
    case "mog":
      return MOGImage;
    case "ostium":
      return OstiumImage;
    case "boden":
      return BodenImage;
    case "tremp":
      return TrempImage;
    case "zro":
      return ZroImage;
    case "zk":
      return ZkImage;
    case "jupiter":
      return JupiterImage;
    case "gmbtc-usd":
      return GMBtcImage;
    case "gmeth-usd":
      return GMEthImage;
    case "tao":
      return TaoImage;
    case "io":
      return IoImage;
    case "sats":
      return SatsImage;
    case "safe":
      return SafeImage;
    case "tlos":
      return TlosImage;
    case "ondo":
      return OndoImage;
    case "kas":
      return KasImage;
    case "taiko":
      return TaikoImage;
    case "not":
      return NotImage;
    case "ena":
      return EnaImage;
    case "ar":
      return ArImage;
    case "alt":
      return AltImage;
    case "beam":
      return BeamImage;
    case "bome":
      return BomeImage;
    case "core":
      return CoreImage;
    case "egld":
      return EgldImage;
    case "jasmy":
      return JasmyImage;
    case "mew":
      return MewImage;
    case "mnt":
      return MntImage;
    case "neo":
      return NeoImage;
    case "pendle":
      return PendleImage;
    case "people":
      return PeopleImage;
    case "pixel":
      return PixelImage;
    case "popcat":
      return PopcatImage;
    case "prime":
      return PrimeImage;
    case "trb":
      return TrbImage;
    case "turbo":
      return TurboImage;
    case "xtz":
      return XtzImage;
    case "zen":
      return ZenImage;
    case "bico":
      return BicoImage;
    case "om":
      return OMImage;
    case "joe":
      return JoeImage;
    case "coq":
      return CoqInuImage;
    case "cj":
      return CJImage;
    case "cl":
      return CLImage;
    case "hg":
      return HGImage;
    case "hogs":
      return HOGSImage;
    case "hsi":
      return HSIImage;
    case "jpy":
      return JPYImage;
    case "lco":
      return LCOImage;
    case "le":
      return LEImage;
    case "ng":
      return NGImage;
    case "nik":
      return NIKImage;
    case "xpd":
      return XPDImage;
    case "xpt":
      return XPTImage;
    case "zs":
      return ZSImage;
    case "dmail":
      return DmailImage;
    case "yvusdc":
      return YvUSDCImage;
    case "yvweth":
      return YvwETHImage;
    case "yvdai":
      return YvDAIImage;
    case "stecrv":
      return StecrvImage;
    case "susde":
      return SusdeImage;
    case "stkcvxgusd3crv":
      return Stkcvxgusd3crvImage;
    case "stkcvxcrvcvxeth":
      return StkcvxcrvcvxethImage;
    case "cvxgusd3crv":
      return Cvxgusd3crvImage;
    case "gusd3crv":
      return Gusd3crvImage;
    case "stkcvxstecrv":
      return StkcvxstecrvImage;
    case "rsweth":
      return RswethImage;
    case "stkcvxcrvfrax":
      return StkcvxcrvfraxImage;
    case "crvplain3andsusd":
      return Crvplain3andsusdImage;
    case "stkcvx3crv":
      return Stkcvx3crvImage;
    case "cvxstecrv":
      return CvxstecrvImage;
    case "stkcvxcrvplain3andsusd":
      return Stkcvxcrvplain3andsusdImage;
    case "frax3crv":
    case "frax3crv-f":
      return Frax3crvImage;
    case "stkcvxfrax3crv":
    case "stkcvxfrax3crv-f":
      return Stkcvxfrax3crvImage;
    case "stkcvxcrvusdusdt":
    case "stkcvxcrvusdusdt-f":
      return StkcvxcrvusdusdtImage;
    case "stkcvxlusd3crv":
    case "stkcvxlusd3crv-f":
      return Stkcvxlusd3crvImage;
    case "yvcurve-steth":
      return YvCurveStethImage;
    case "mavia":
      return MaviaImage;
    case "usde":
      return UsdeImage;
    case "render":
      return RenderImage;
    case "michi":
      return MichiImage;
    case "vrtx":
      return VrtxImage;
    case "gearbox":
      return GearboxImage;
    case "aurora":
      return AuroraImage;
    case "foxy":
      return FoxyImage;
    case "paxg":
      return PaxgImage;
    case "drift":
      return DriftImage;
    case "gme":
      return GMEImage;
    case "weeths":
      return weETHs;
    case "eurc":
      return EurcImage;
    case "retardio":
      return RetardioImage;
    case "eigenlayer":
    case "eigenda":
      return EigenDAImage;
    case "eoracle":
      return EoracleImage;
    case "hyperlane":
      return HyperlaneImage;
    case "openlayer":
      return OpenLayerImage;
    case "xterio mach":
      return XterioMachImage;
    case "witness chain":
    case "itness chain":
      return WitnessChainImage;
    case "omni network":
      return OmniNetworkImage;
    case "lagrange zk":
    case "lagrange state":
      return LagrangeImage;
    case "gm mach":
      return GMMachImage;
    case "dodo mach":
      return DodoMachImage;
    case "brevis":
      return BrevisImage;
    case "automata":
      return AutomataImage;
    case "arpa":
      return ArpaImage;
    case "cyber mach":
      return CyberMachImage;
    case "altlayer mach":
      return AltLayerImage;
    case "renzo":
      return RenzoImage;
    case "kelp":
      return KelpImage;
    case "puffer":
      return PufferImage;
    case "etherfi":
      return EtherfiImage;
    case "usdy":
      return UsdyImage;
    case "pol":
      return PolImage;
    case "hnt":
      return HeliumImage;
    case "neiro":
      return NeiroImage;
    case "cbbtc":
      return CbBtcImage;
    case "ebtc":
    case "pt-ebtc-26dec2024":
      return EBtc;
    case "lbtc":
    case "pt-cornlbtc-26dec2024":
      return LBtc;
    case "try":
      return TryImage;
    case "sundog":
      return SundogImage;
    case "solvbtc.bbn":
    case "solvbtc":
      return SolvBTC;
    case "usds":
      return UsdsImage;
    case "eigen":
      return EigenImage;
    case "wsuperoeth":
      return WsuperOethImage;
    case "glv":
      return GlvImage;
    case "ausd":
      return AUSD;
    case "trumpwin":
      return TrumpWinImage;
    case "cetus":
      return CetusImage;
    case "zbcn":
      return ZbcnImage;
    case "send":
      return SendImage;
    case "ont":
      return OntImage;
    case "perp":
      return PerpImage;
    case "qtum":
      return QtumImage;
    case "meme":
      return MemeImage;
    case "pumpbtc":
      return PumpBtcImage;
    case "clv":
      return ClvImage;
    case "id":
      return IdImage;
    case "luce":
      return LuceImage;
    case "moodeng":
      return MoodengImage;
    case "order":
      return OrderImage;
    case "pnut":
      return PnutImage;
    case "ray":
      return RayImage;
    case "xyo":
      return XyoImage;
    case "deep":
      return DeepImage;
    case "grass":
      return GrassImage;
    case "mad":
    case "mad,raydium,madhpjrn6bd8t78rsy7nusunwwa2hu8bypobzprhbhv":
      return MadImage;
    case "ath":
      return AthImage;
    case "skl":
      return SklImage;
    case "hawktuah,raydium,4gfe6mbdorsy5blbiumrgetr6pzcjyfxmdm5ehsgpump":
      return HawkTuahImage;
    case "pirate":
      return PirateImage;
    case "powsche,raydium,8ckishhjdhjv4luoirmluhqg58cukbyjrtcp4z3mcxnf":
      return PowscheImage;
    case "bcut":
      return BitsCrunchImage;
    case "vet":
      return VetImage;
    case "g":
      return GImage;
    case "fartcoin,raydium,9bb6nfecjbctnnlfko2fqvqbq8hhm13kcyycdqbgpump":
    case "fartcoin":
      return FartCoinImage;
    case "moca":
      return MocaImage;
    case "move":
      return MoveImage;
    case "chaos":
      return EdgeImage;
    case "usual":
      return UsualImage;
    case "spx":
      return SpxImage;
    case "zircuit":
      return ZircuitImage;
    case "spark":
      return SparkImage;
    case "swell":
    case "swell simple staking":
      return SwellImage;
    case "mitosis":
      return MitosisImage;
    case "karak":
      return KarakImage;
    case "fluid":
      return FluidImage;
    case "pt-solvbtc.bbn-27mar2025":
      return PtSolvBtcImage;
    case "pt-susde-mar25":
      return PtSusdeImage;
    case "pt-usde-27mar2025":
      return PtUSDeImage;
    case "eeigen":
      return EEigenImage;
    case "liquid-eth":
    case "liquideth":
      return LiquidEthImage;
    case "weethk":
      return WeEthKImage;
    case "eusd":
      return EUsdImage;
    case "meth":
      return MEthImage;
    case "trump":
      return TrumpImage;
    case "melania":
      return MelaniaImage;
    case "merkle":
      return MerkleImage;
    case "aries":
      return AriesImage;
    case "cellana":
      return CellanaImage;
    case "superposition":
      return SuperpositionImage;
    case "echelon":
      return EchelonImage;
    case "thala":
      return ThalaImage;
    case "joule":
      return JouleImage;
    case "amnis":
      return AmnisImage;
    case "meso":
      return MesoImage;
    case "usdt0":
    case "usd₮0":
      return Usdt0Image;
    case "anime":
      return AnimaImage;
    case "ai16z":
      return Ai16zImage;
    case "the":
      return TheImage;
    case "susds":
      return sUSDSImage;
    case "bera":
      return BeraImage;
    case "yvusds-1":
      return yvUSDSImage;
    case "yvusdc-1":
      return yvUSDC1Image;
    case "yvusdt-1":
      return yvUSDT1Image;
    case "yvweth-1":
      return yvWETH1Image;
    case "pengu":
      return PenguImage;
    case "virtual":
      return VirtualImage;
    default:
      return null;
  }
};
