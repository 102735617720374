import { Box, CustomReactSelect, Typography } from "@frontend/ui";
import { PageHeader } from "@frontend/ui/page-header";
import { useClientConfig } from "src/pages/ccar-lending-page/clients-config";
import { useLocation, useParams } from "react-router-dom";
import { TabsNavigation } from "src/components/layouts";
import { NavigationTabProps } from "src/components/layouts/tabs-navigation";
import { RouteParams, RoutePath } from "src/config/routes";
import { getIconSrc } from "@frontend/ui/crypto-icon/get-icon-src";
import { groupBy } from "lodash";
import { useMarketsContext } from "../../../context/markets-context";

export const useMarketsPageTabs: () => NavigationTabProps[] = () => {
  const { clientName, marketId } = useParams();
  const { hasEMode, isMultiMarket, markets } = useClientConfig();

  const replaceParams = (path: string) =>
    path.replace(RouteParams.ClientName, clientName!).replace(RouteParams.MarketId, marketId!);

  return [
    ...(isMultiMarket ? [{ label: "Overview", path: replaceParams(RoutePath.Risk.MarketDetailsOverview) }] : []),
    { label: "Assets", path: replaceParams(RoutePath.Risk.MarketDetailsListedAssets) },
    ...(hasEMode ? [{ label: "E-Mode", path: replaceParams(RoutePath.Risk.MarketEModes) }] : []),
    ...(markets?.assetRecommendations
      ? [{ label: "Recommendations", path: replaceParams(RoutePath.Risk.MarketDetailsRecommendations()) }]
      : []),
  ];
};

const MarketDropdownHeader = () => {
  const { clientName, marketId } = useParams();
  const location = useLocation();
  const { markets } = useMarketsContext();
  const { showChainMarketsBreakdown, chains, marketType } = useClientConfig();
  const marketsPageTabs = useMarketsPageTabs();

  const marketsByChain = groupBy(markets, "chain");
  const selectedMarketData = markets.find((m) => m.id === marketId!);

  const marketsToSelect =
    showChainMarketsBreakdown && selectedMarketData ? marketsByChain[selectedMarketData.chain] : markets;

  const dropdownOptions = marketsToSelect.map(({ id, name }) => ({
    value: id,
    label: name,
    cryptoIcon: marketType !== "GeneralMarket" && getIconSrc(name) ? name.toLowerCase() : undefined,
    link: location.pathname.replace(marketId!, id),
  }));
  const selectedMarket = dropdownOptions.find((option) => option.value === marketId!);

  const chainDropdownOptions = chains.map((chain) => ({
    value: chain,
    label: chain,
    cryptoIcon: chain,
    link: location.pathname.replace(marketId!, marketsByChain[chain]?.[0]?.id || ""),
  }));
  const selectedChain = chainDropdownOptions.find((option) => option.value === selectedMarketData?.chain);

  const hasMarkets = markets && markets?.length > 1;
  const hasTabs = marketsPageTabs && marketsPageTabs?.length > 1;
  const showHeader = hasMarkets || hasTabs;

  return showHeader ? (
    <PageHeader
      backLink={RoutePath.Risk.Markets.replace(RouteParams.ClientName, clientName!)}
      pageTitle={
        <Box sx={{ display: "inline-flex", alignItems: "center", gap: 2 }} data-testid="page-title">
          {showChainMarketsBreakdown && selectedMarketData && (
            <>
              <CustomReactSelect
                options={chainDropdownOptions}
                value={selectedChain}
                isSearchable={false}
                variant="h3"
              />
              <Typography variant="h3" color="text.primary">
                |
              </Typography>
            </>
          )}
          {hasMarkets && (
            <CustomReactSelect options={dropdownOptions} value={selectedMarket} isSearchable={false} variant="h3" />
          )}
        </Box>
      }
      suffixComponent={<TabsNavigation variant="sub-nav" tabs={marketsPageTabs} />}
      showBackButton={markets && markets?.length > 1}
    />
  ) : (
    <Box />
  );
};

export default MarketDropdownHeader;
