import { ValueOverTimeChart } from "@frontend/ui";
import { TimeSpan } from "@frontend/ui/echarts/types";
import { formatDuration } from "@frontend/ui/utils/formatters";
import { WithdrawalsAggData } from "../../../../generated";

type Props = {
  timeSpan: TimeSpan;
  isLoading: boolean;
  data?: WithdrawalsAggData[];
};

const WithdrawalsWaitTimeChart = ({ timeSpan, isLoading, data }: Props) => (
  <ValueOverTimeChart
    title="Average Withdrawal Wait Time"
    series={[
      {
        label: "Avg Wait Time",
        // Remove the last point from this chart - it always zero
        data: (data || []).slice(1).map((d) => [d.timestamp, d.avgWaitTimeSeconds]),
      },
    ]}
    isLoading={isLoading}
    timeSpan={timeSpan}
    yAxisLabelFormatter={(waitTimeSeconds: number) =>
      `${formatDuration({
        milliseconds: waitTimeSeconds * 1000,
        notation: "compact",
        showHighestUnitOnly: true,
        zeroUnit: "H",
      })}`
    }
    tooltipValueFormatter={(waitTimeSeconds: number) =>
      formatDuration({
        milliseconds: waitTimeSeconds * 1000,
        notation: "standard",
        showSeconds: false,
        compactHours: 24,
      })
    }
    hideLegend
  />
);

export default WithdrawalsWaitTimeChart;
