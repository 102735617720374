import { Grid, ValueCard, ValueCardProps } from "@frontend/ui";
import { getDeltaFields } from "@frontend/ui/trend";
import { Chain, useIncentiveDetailsCardsQuery } from "../../../generated";

type Props = {
  chain: Chain;
  selectedProtocol: string;
};
const IncentiveDetailsCards = ({ chain, selectedProtocol }: Props) => {
  const { data, loading } = useIncentiveDetailsCardsQuery({
    variables: { chain, aptosProtocol: selectedProtocol },
  });

  const createCard = (
    title: string,
    currentValue: number | null | undefined,
    prevValue: number | null | undefined,
  ): ValueCardProps => ({
    title,
    value: currentValue ?? (loading ? undefined : "N/A"),
    ...getDeltaFields(currentValue ?? undefined, prevValue ?? undefined, true, true),
  });

  const cards: ValueCardProps[] = [
    createCard(
      "APT received by foundation",
      data?.incentiveDetailsCards?.aptReceivedByFoundation?.value,
      data?.incentiveDetailsCards?.aptReceivedByFoundation?.prevValue,
    ),
    createCard(
      "APT distributed to users",
      data?.incentiveDetailsCards?.aptDistributionToUsers?.value,
      data?.incentiveDetailsCards?.aptDistributionToUsers?.prevValue,
    ),
    createCard(
      "Number of interacted wallets",
      data?.incentiveDetailsCards?.numberOfInteractedWallets?.value,
      data?.incentiveDetailsCards?.numberOfInteractedWallets?.prevValue,
    ),
    createCard(
      "Total volume",
      data?.incentiveDetailsCards?.totalVolume?.value,
      data?.incentiveDetailsCards?.totalVolume?.prevValue,
    ),
  ];

  return (
    <Grid container>
      {cards.map((card, i) => (
        <Grid key={`${card.title}${i.toString()}`} item xs={6} md={4} lg={3}>
          <ValueCard {...card} />
        </Grid>
      ))}
    </Grid>
  );
};

export default IncentiveDetailsCards;
