import { Grid, ValueCard, ValueCardProps } from "@frontend/ui";
import { getDeltaFields } from "@frontend/ui/trend";
import { Chain, useNativeTokenDistributionQuery, useTotalNumberOfClaimedRewardWalletsQuery } from "../../../generated";

type Props = {
  chain: Chain;
};
const IncentiveOverviewCards = ({ chain }: Props) => {
  const { data: totalAPTDistributed } = useNativeTokenDistributionQuery({
    variables: { chain },
  });

  const { data: totalNumberOfWalletsClaimed } = useTotalNumberOfClaimedRewardWalletsQuery({
    variables: { chain },
  });
  const cards: ValueCardProps[] = [
    {
      title: "Total APT distributed to protocols",
      value: totalAPTDistributed?.nativeTokenDistribution.value,
      ...getDeltaFields(
        totalAPTDistributed?.nativeTokenDistribution.value,
        totalAPTDistributed?.nativeTokenDistribution.prevValue || undefined,
        true,
        true,
      ),
    },
    {
      title: "Total Number of wallets that claimed rewards",
      value: totalNumberOfWalletsClaimed?.totalNumberOfClaimedRewardWallets.value,
      ...getDeltaFields(
        totalNumberOfWalletsClaimed?.totalNumberOfClaimedRewardWallets.value,
        totalNumberOfWalletsClaimed?.totalNumberOfClaimedRewardWallets.prevValue || undefined,
        true,
        true,
      ),
    },
  ];

  return (
    <Grid container>
      {cards.map((card, i) => (
        <Grid key={`${card.title}${i.toString()}`} item xs={12} md={6}>
          <ValueCard {...card} />
        </Grid>
      ))}
    </Grid>
  );
};

export default IncentiveOverviewCards;
