import { StackedBarChart } from "@frontend/ui";
import { capitalizeFirstLetter } from "@frontend/ui/utils/formatters";
import { Chain, useNativeTokenDistributionByProtocolQuery } from "../../../generated";

export const TotalAptDistributionPerProtocol = ({ chain }: { chain: Chain }) => {
  const { data, loading } = useNativeTokenDistributionByProtocolQuery({
    variables: { chain },
  });

  return (
    <StackedBarChart
      chartHeight={325}
      title="Total APT Distribution per Protocol"
      isLoading={loading}
      series={[
        {
          label: "APT",
          data: [...(data?.nativeTokenDistributionByProtocol || [])]
            .sort((a, b) => b.value - a.value)
            .map((c) => [capitalizeFirstLetter(c.category), c.value]),
        },
      ]}
      showXAxisLine={false}
      showLegend={false}
      cryptoCurrency="APT"
      rotateXAxis={45}
    />
  );
};
