import { Header, RenderData } from "@frontend/types";
import { ChaosTable, chainCell, currencyCell, iconsCell, markedCell, numberCell, textCell } from "@frontend/ui";
import { capitalizeFirstLetter } from "@frontend/ui/utils/formatters";
import { PageTracker } from "src/components/page-tracker";
import { PoolSortBy, SortOrder, usePoolsQuery } from "src/pages/ccar-lending-page/generated";

type Props = {
  asset: string;
};

const PoolsTable = PageTracker(({ asset }: Props) => {
  const { data, loading } = usePoolsQuery({
    variables: {
      asset,
      input: {
        limit: null,
        skip: 0,
        sort: PoolSortBy.TotalLiquidity,
        order: SortOrder.Descending,
      },
    },
  });

  const headers: Header[] = [
    {
      renderType: "TEXT",
      text: "Chain",
      width: "15%",
    },
    {
      renderType: "TEXT",
      text: "Protocol",
      width: "15%",
    },
    {
      renderType: "TEXT",
      text: "Pool Name",
      width: "25%",
    },
    {
      renderType: "TEXT",
      text: "Tokens",
      width: "18%",
    },
    {
      renderType: "TEXT",
      text: "TVL",
      prefix: "$",
    },
    {
      renderType: "TEXT",
      text: `${asset} Value`,
      width: "10%",
    },
    {
      renderType: "TEXT",
      text: `${asset} Amount`,
      width: "10%",
    },
    {
      renderType: "TEXT",
      text: "% Pool",
      width: "10%",
    },
    {
      renderType: "TEXT",
      text: `${asset} Price`,
      width: "10%",
    },
  ];

  const pools = data?.pools?.items || [];
  const tableData: RenderData[][] = pools.map((pool) => {
    const poolAsset = pool.tokens.find((t) => t.symbol.toLowerCase() === asset.toLowerCase());
    const protocol = String(pool?.source || "");
    const poolAssetAmount = Number(poolAsset && poolAsset.balance) ?? 0;
    const poolAssetPrice = poolAsset?.usdPrice || 0;
    const poolTvl = pool.totalLiquidity
      ? pool.totalLiquidity
      : pool.tokens
          .map((t) => (t.balance ? Number(t.usdPrice) * Number(t.balance) : 0))
          .reduce((sum, value) => Number(sum) + Number(value));
    return [
      chainCell(pool.chain, pool.chain),
      chainCell(capitalizeFirstLetter(protocol), protocol, protocol),
      textCell(pool.name),
      iconsCell(pool.tokens.map((t) => String(t.symbol))),
      currencyCell(poolTvl),
      poolAssetAmount > 0 && poolAssetPrice > 0
        ? currencyCell(poolAssetAmount * poolAssetPrice, { maximumFractionDigits: 0 })
        : textCell(""),
      poolAssetAmount > 0 ? numberCell(poolAssetAmount) : textCell(""),
      poolAssetAmount > 0 && poolAssetPrice > 0 && poolTvl > 0
        ? numberCell((poolAssetAmount * poolAssetPrice * 100) / poolTvl)
        : textCell(""),
      poolAssetPrice > 0 ? currencyCell(poolAssetPrice, { maximumFractionDigits: 5 }) : textCell(""),
    ] as RenderData[];
  });

  const onClickRowHref = (rowId: number) => {
    const pool = pools[rowId];
    return `${pool.address}`;
  };

  return (
    <ChaosTable
      title={`${asset} Pools`}
      isLoading={loading}
      isFullHeight
      data={tableData}
      headers={headers}
      initialSortBy={5}
      isInitialSortDesc
      pageSize={10}
      rowHref={onClickRowHref}
    />
  );
});

export default PoolsTable;
