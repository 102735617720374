import { Grid } from "@frontend/ui";
import { getDeltaFields } from "@frontend/ui/trend";
import { ValueCard, ValueCardProps } from "@frontend/ui/value-card";
import { UserEngagementData } from "../use-user-engagement-data";

type Props = {
  data: UserEngagementData;
};

const UserEngagementCards = ({ data }: Props) => {
  const cards: ValueCardProps[] = [
    {
      title: "Daily Active Users (24H)",
      value: data.dailyActiveUsers?.value,
      ...getDeltaFields(data.dailyActiveUsers?.value, data.dailyActiveUsers?.prevValue || undefined, true, true),
    },
    {
      title: "Monthly Active Users (30D)",
      value: data.monthlyActiveUsers?.value,
      ...getDeltaFields(data.monthlyActiveUsers?.value, data.monthlyActiveUsers?.prevValue || undefined, true, true),
    },
    {
      title: "User Transaction Count (30D)",
      value: data.thirtyDaysUserTransactionCount?.value,
      ...getDeltaFields(
        data.thirtyDaysUserTransactionCount?.value,
        data.thirtyDaysUserTransactionCount?.prevValue || undefined,
        true,
        true,
      ),
    },
  ];

  return (
    <Grid container>
      {cards.map((card, i) => (
        <Grid key={`${card.title}${i.toString()}`} item xs={6} md={4} lg={3}>
          <ValueCard {...card} />
        </Grid>
      ))}
    </Grid>
  );
};

export default UserEngagementCards;
