import { useState } from "react";
import { Box, CustomSwitch } from "@frontend/ui";
import { PageHeader } from "@frontend/ui/page-header";
import { useUserEngagementData } from "./use-user-engagement-data";
import {
  ActiveUsersByProtocol,
  NewUsersHistory,
  UserEngagementCards,
  UsersMonthlyRetentionHeatmap,
} from "./components";
import ChainsHistoryChart from "../components/chains-history-chart";
import { TimeSpan } from "../../generated";

const UserEngagement = () => {
  const [isNumberOfUsersHistoryMonthly, setIsNumberOfUsersHistoryMonthly] = useState(true);
  const data = useUserEngagementData(isNumberOfUsersHistoryMonthly);
  return (
    <Box display="flex" flexDirection="column">
      <PageHeader showBackButton={false} pageTitle="User Engagement" />
      <UserEngagementCards data={data} />
      <ChainsHistoryChart
        title={`${isNumberOfUsersHistoryMonthly ? "Monthly" : "Daily"} Active Users`}
        yAxisLabel="Number of Active Users"
        description={`Number of ${
          isNumberOfUsersHistoryMonthly ? "monthly" : "daily"
        } addresses initiating at least one transaction`}
        data={data.chainsNumberOfUsersHistory}
        isLoading={data.isLoadingChainsNumberOfUsersHistory}
        isCurrency={false}
        isBarChart
        suffix={
          <CustomSwitch
            onChange={() => setIsNumberOfUsersHistoryMonthly((prev) => !prev)}
            checked={isNumberOfUsersHistoryMonthly}
            checkedLabel="MAU"
            uncheckedLabel="DAU"
          />
        }
        timeSpan={TimeSpan.Year}
        showMonthTooltip={isNumberOfUsersHistoryMonthly}
      />
      <ActiveUsersByProtocol data={data} />
      <NewUsersHistory data={data} />
      <UsersMonthlyRetentionHeatmap data={data} />
    </Box>
  );
};

export default UserEngagement;
