import { FC } from "react";
import { Grid, StackedBarChart } from "@frontend/ui";
import { formatDateAndTime, formatDay } from "@frontend/ui/utils/formatters";
import { Chain, useUserChartsDataByProtocolQuery } from "../../../generated";

interface RewardClaimerChartsProps {
  chain: Chain;
  selectedProtocol: string;
  address: string;
}

const CHART_HEIGHT = 348;

export const RewardClaimerCharts: FC<RewardClaimerChartsProps> = ({ chain, selectedProtocol, address }) => {
  const { loading, data } = useUserChartsDataByProtocolQuery({
    variables: { chain, selectedProtocol, address },
  });

  return (
    <Grid container>
      <Grid item xs={6}>
        <StackedBarChart
          title="APT Claim Distribution per Protocol"
          series={(data?.claimDistributionPerProtocol || []).map((item) => ({
            label: item.category,
            data: [[item.category, item.value]],
          }))}
          showLegend={false}
          showXAxisLine={false}
          currency="USD"
          chartHeight={CHART_HEIGHT}
          isLoading={loading}
        />
      </Grid>
      <Grid item xs={6}>
        <StackedBarChart
          title="Bridge Flow Trends"
          series={Object.values(
            (data?.bridgeNetFlowsByProtocol || []).reduce(
              (acc, curr) => {
                curr.categories.forEach((category) => {
                  if (!acc[category.category]) {
                    acc[category.category] = {
                      label: category.category,
                      data: [],
                    };
                  }
                  acc[category.category].data.push([
                    formatDateAndTime(new Date(curr.timestamp), false),
                    category.value,
                  ]);
                });

                return acc;
              },
              {} as Record<
                string,
                {
                  label: string;
                  data: [string, number][];
                }
              >,
            ),
          )}
          showXAxisLine={false}
          currency="USD"
          chartHeight={CHART_HEIGHT}
          isLoading={loading}
        />
      </Grid>
      <Grid item xs={12}>
        <StackedBarChart
          title="Volume Activity Per Protocol"
          series={Object.values(
            (data?.volumeActivityPerProtocol || []).reduce(
              (acc, curr) => {
                curr.categories.forEach((category) => {
                  if (!acc[category.category]) {
                    acc[category.category] = {
                      label: category.category,
                      data: [],
                    };
                  }
                  acc[category.category].data.push([
                    formatDateAndTime(new Date(curr.timestamp), false),
                    category.value,
                  ]);
                });

                return acc;
              },
              {} as Record<
                string,
                {
                  label: string;
                  data: [string, number][];
                }
              >,
            ),
          )}
          showLegend
          showXAxisLine={false}
          currency="USD"
          chartHeight={CHART_HEIGHT}
          isLoading={loading}
        />
      </Grid>
      <Grid item xs={6}>
        <StackedBarChart
          title="Daily APT Claimed Per Protocol"
          series={Object.values(
            (data?.dailyClaimedRewardsPerProtocol || []).reduce(
              (acc, curr) => {
                curr.categories.forEach((category) => {
                  if (!acc[category.category]) {
                    acc[category.category] = {
                      label: category.category,
                      data: [],
                    };
                  }
                  acc[category.category].data.push([
                    formatDateAndTime(new Date(curr.timestamp), false),
                    category.value,
                  ]);
                });

                return acc;
              },
              {} as Record<
                string,
                {
                  label: string;
                  data: [string, number][];
                }
              >,
            ),
          )}
          showXAxisLine={false}
          currency="USD"
          chartHeight={CHART_HEIGHT}
          isLoading={loading}
        />
      </Grid>
      <Grid item xs={6}>
        <StackedBarChart
          title="APT Sold Daily"
          series={[
            {
              label: "dailySoldRewards",
              data: (data?.dailySoldRewards || []).map((item) => [
                formatDay(new Date(item.timestamp)),
                item.categories.reduce((acc, curr) => acc + curr.value, 0),
              ]),
            },
          ]}
          showLegend={false}
          showXAxisLine={false}
          currency="USD"
          chartHeight={CHART_HEIGHT}
          isLoading={loading}
        />
      </Grid>
    </Grid>
  );
};
