import dayjs from "dayjs";
import { Theme, useMediaQuery } from "@mui/material";
import { TimeSpan } from "./generated";

export type XTimeSpan = TimeSpan.Month | TimeSpan.Year;

export const daysAgoByTimeSpan = {
  [TimeSpan.Day]: 1,
  [TimeSpan.Week]: 7,
  [TimeSpan.Month]: 30,
  [TimeSpan.Quarter]: 90,
  [TimeSpan.Year]: 365,
} as const;

export const xAxisDateFormatter = (timestamp: number, timeSpan: TimeSpan) => {
  const date = dayjs(timestamp);
  if (!timeSpan) {
    return date.format("YYYY");
  }

  const daysAgo = daysAgoByTimeSpan[timeSpan];

  if (daysAgo > daysAgoByTimeSpan[TimeSpan.Month] && daysAgo <= daysAgoByTimeSpan[TimeSpan.Year]) {
    return date.format("MMM");
  }

  return undefined;
};

const YEAR_AXIS_SPLIT = 12;

export const useXAxisSplit = () => {
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));

  if (!isMobile) {
    return YEAR_AXIS_SPLIT;
  }

  return 5;
};
