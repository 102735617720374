import { renderToString } from "react-dom/server";
import {
  TooltipComponentFormatterCallbackParams,
  DefaultLabelFormatterCallbackParams,
  EChartsOption,
  HeatmapSeriesOption,
} from "echarts";
import { palette } from "../../../theme/palette";
import { formatAmount } from "../../../utils/formatters";
import { HeatmapRowData } from "./types";
import { Box } from "../../../box";

const cellFormatter = (isPercent: boolean) => (params: DefaultLabelFormatterCallbackParams) => {
  const { data } = params;
  if (!Array.isArray(data)) return "-";
  const value = Number(data[2]);

  if (isPercent) {
    return formatAmount(value, { isPercent: true });
  }
  return formatAmount(value);
};

export type HeatmapChartProps = {
  heatmapRowsData: HeatmapRowData[];
  yAxisLabel?: string;
  xAxisLabel?: string;
  hideCellLabels?: boolean;
  isPercent: boolean;
  customTooltip?: (params: TooltipComponentFormatterCallbackParams) => JSX.Element | null;
};

export const getHeatmapChartOption = ({
  heatmapRowsData,
  yAxisLabel,
  xAxisLabel,
  hideCellLabels,
  isPercent,
  customTooltip,
}: HeatmapChartProps): EChartsOption => {
  const yAxisData = heatmapRowsData.map((row) => row.rowId);
  const xAxisData = [...new Set(heatmapRowsData.flatMap((d) => d.cells.map(({ cellId }) => cellId)))];
  const heatmapValues = heatmapRowsData
    .map((row, yIdx) => row.cells.map((cell, xIdx) => [xIdx, yIdx, cell.value] as [number, number, number]))
    .flat();
  const highlightCells = heatmapRowsData.reduce(
    (acc, row, yIdx) => {
      const newAcc = [...acc];

      row.cells.forEach((cell, xIdx) => {
        if (cell.highlight) {
          newAcc.push({
            value: [xIdx, yIdx, cell.value],
            borderColor: cell.highlight.color,
            name: cell.highlight.name,
          });
        }
      });

      return newAcc;
    },
    [] as {
      value: [number, number, number | undefined];
      borderColor: string;
      name: string;
    }[],
  );
  const maxValue = heatmapValues.reduce((max, [, , value]) => Math.max(max, value), -Infinity);
  const minValue = heatmapValues.reduce((min, [, , value]) => Math.min(min, value), Infinity);

  const cellLabelOption: HeatmapSeriesOption["label"] = {
    show: !hideCellLabels,
    formatter: cellFormatter(isPercent),
    fontSize: 14,
    fontWeight: 400,
  };
  const option: EChartsOption = {
    grid: {
      top: highlightCells.length ? 50 : 0,
      left: yAxisLabel ? 40 : 10,
      bottom: xAxisLabel ? 40 : 0,
      right: 10,
    },
    tooltip: {
      show: !!customTooltip,
      formatter: customTooltip ? (params) => renderToString(customTooltip(params) || <Box />) : undefined,
      padding: 0,
      renderMode: "auto",
      position: "right",
      trigger: "item",
      confine: true,
    },
    xAxis: {
      type: "category",
      data: xAxisData,
      splitArea: {
        show: true,
        areaStyle: {
          color: [palette.black.main],
        },
      },
      name: xAxisLabel,
      nameLocation: "middle",
      nameGap: 50,
      nameTextStyle: {
        color: palette.white.main,
        fontSize: 14,
      },
      axisLabel: {
        fontSize: 12,
      },
    },
    legend: {
      data: highlightCells.map(({ name }) => name),
    },
    yAxis: {
      type: "category",
      data: yAxisData,
      name: yAxisLabel,
      nameLocation: "middle",
      nameGap: 80,
      nameTextStyle: {
        color: palette.white.main,
        fontSize: 14,
      },
      axisLabel: {
        fontSize: 12,
      },
    },
    visualMap: {
      min: minValue,
      max: maxValue,
      show: false,
      inRange: {
        color: ["#0366C1", "#0758A0", "#0C4880", "#0366C1CC", "#0366C180"],
      },
      formatter: (value: string | number | Date) =>
        isPercent ? formatAmount(Number(value), { isPercent: true }) : formatAmount(Number(value)),
    },
    series: [
      {
        type: "heatmap",
        animation: false,
        data: heatmapValues,
        label: cellLabelOption,
        emphasis: {
          label: {
            show: true,
          },
          itemStyle: {
            shadowBlur: 10,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
      ...highlightCells.map(
        ({ value, borderColor, name }) =>
          ({
            name,
            type: "heatmap",
            color: borderColor,
            animation: false,
            data: [value],
            label: cellLabelOption,
            zlevel: 1,
            itemStyle: {
              borderWidth: 4,
              borderColor,
            },
            emphasis: {
              label: {
                show: true,
              },
              itemStyle: {
                shadowBlur: 10,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          }) as HeatmapSeriesOption,
      ),
    ],
  };

  return option;
};
