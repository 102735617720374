import { Box, List, SubNavTabs } from "@frontend/ui";
import { useParams } from "react-router-dom";
import { ModalWrapper, ModalWrapperProps } from "@frontend/ui/modals";
import { useState } from "react";
import { DisplayProtocolType, displayProtocolTypes, labelByProtocolType, useProtocolGroups } from "./protocol-groups";
import ProtocolSwitcherListItem from "./protocol-switcher-list-item";
import { useProtocolNavigate } from "./use-protocol-navigate";

const ProtocolSwitcherModal = (props: ModalWrapperProps) => {
  const { clientName } = useParams<"clientName">();
  const navigateToProtocol = useProtocolNavigate();

  const protocols = useProtocolGroups();

  const currentProtocolKey = Object.keys(protocols).find((k) => clientName?.includes(k)) || "";
  const currentProtocolType = (protocols[currentProtocolKey]?.type || "lending") as DisplayProtocolType;

  const [selectedProtocolType, setProtocolType] = useState<DisplayProtocolType>(currentProtocolType);
  const protocolsEntries = Object.entries(protocols).filter(([_key, p]) => p.type === selectedProtocolType);

  const [selectedProtocolKey, setSelectedProtocolKey] = useState<string>(currentProtocolKey);
  const selectedProtocol = protocols[selectedProtocolKey];
  const protocolOptions = selectedProtocol.options || [];

  const handleProtocolClick = (protocolKey: string) => {
    const newSelectedProtocol = protocols[protocolKey];
    setSelectedProtocolKey(protocolKey);

    if (!newSelectedProtocol.options) {
      navigateToProtocol(protocolKey, newSelectedProtocol.type, selectedProtocol.type);
    }
  };

  return (
    <ModalWrapper {...props} maxWidth={880} title="Switch Protocol">
      <Box display="flex" flexDirection="column" gap={3}>
        <Box>
          <SubNavTabs
            tabs={displayProtocolTypes.map((protocolType) => ({
              label: labelByProtocolType[protocolType],
              value: protocolType,
            }))}
            value={displayProtocolTypes.indexOf(selectedProtocolType)}
            onChange={(_e, newTabIndex) => {
              const newProtocolType = displayProtocolTypes[newTabIndex];
              if (!newProtocolType) return;
              setProtocolType(newProtocolType);
            }}
          />
        </Box>

        <Box
          sx={{
            bgcolor: "black.main",
            borderRadius: "2px",
            overflow: "hidden",
            minHeight: "384px",
          }}
        >
          <Box sx={{ display: "flex", height: "fit-content", border: "1px solid", borderColor: "greys.800" }}>
            <List
              sx={{
                p: 0,
                flex: 1,
                borderRight: "1px solid",
                borderRightColor: "greys.800",
              }}
            >
              {protocolsEntries.map(([key, protocol], i) => (
                <ProtocolSwitcherListItem
                  key={key}
                  label={protocol.label}
                  icon={protocol.icon}
                  isSelected={clientName?.includes(key)}
                  showChevron={!!protocol.options}
                  isLastItem={i === protocolsEntries.length - 1}
                  onClick={() => handleProtocolClick(key)}
                />
              ))}
            </List>
            <List
              sx={{
                p: 0,
                flex: 1,
              }}
            >
              {(selectedProtocol.type === selectedProtocolType ? protocolOptions : []).map((item, i) => (
                <ProtocolSwitcherListItem
                  key={item.value}
                  label={item.label}
                  isSelected={item.value === clientName}
                  isLastItem={i === protocolOptions.length - 1}
                  onClick={() =>
                    navigateToProtocol(item.value, selectedProtocol.type, protocols[currentProtocolKey].type)
                  }
                />
              ))}
            </List>
          </Box>
        </Box>
      </Box>
    </ModalWrapper>
  );
};
export default ProtocolSwitcherModal;
