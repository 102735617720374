import { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import ReactECharts from "echarts-for-react";
import { Box } from "../../../box";
import ECharts from "../../echarts";
import { ShimmerLoader } from "../../../shimmer-loader";
import { StringChartProps } from "../../types";
import { downloadCsv } from "../../../utils/csv";
import { transformToCSV } from "../export-utils";
import { ChartHeader } from "../chart-header";
import { ChartWrapper } from "../chart-wrapper";
import PieChartCustomLegend, { LegendItem } from "./pie-chart-custom-legend";
import { useMediaQuery, useTheme } from "../../../theme";
import { getPieChartOptions } from "./get-pie-chart-option";
import { handleLegendClick, handleLegendHover } from "./chart-handlers";

export const PieChart = ({
  title,
  tooltip,
  emptyState,
  emptyStateText,
  description,
  headerSuffix,
  isLoading,
  isPercent,
  currency,
  series,
  showLegendCryptoIcon,
  chartHeight = 320,
  cryptoCurrency,
  showLabelInEmphasis,
  onClickLegendItem,
  getLegendItemSubtitle,
  formatLegendItemLabel,
  shouldHideIcon,
  getLegendItemTooltip,
}: StringChartProps & {
  showLegendCryptoIcon?: boolean;
  showLabelInEmphasis?: boolean;
  onClickLegendItem?: (name: string) => void;
  getLegendItemSubtitle?: (name: string) => ReactNode | undefined;
  formatLegendItemLabel?: (name: string) => string;
  shouldHideIcon?: (name: string) => boolean;
  getLegendItemTooltip?: (name: string) => string;
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const chartRef = useRef<ReactECharts>(null);
  const [legendData, setLegendData] = useState<LegendItem[]>([]);

  const filteredSeries = useMemo(
    () =>
      series.map((s) => ({
        ...s,
        data: s.data.filter(([, value]: [string, number]) => value !== 0),
      })),
    [series],
  );

  const exportToCsv = () => {
    downloadCsv(["Label", "Value"], transformToCSV(filteredSeries), title || "chart_data");
  };

  useEffect(() => {
    if (chartRef.current) {
      const echartsInstance = chartRef.current.getEchartsInstance();
      const defaultColors = echartsInstance.getOption().color as string[];
      const enrichedLegendData = filteredSeries[0].data
        .sort((a, b) => b[1] - a[1])
        .map(([name, value], index) => ({
          name,
          value,
          color: defaultColors[index % defaultColors.length],
          hideIcon: shouldHideIcon?.(name),
        }));
      setLegendData(enrichedLegendData);
    }
  }, [filteredSeries, shouldHideIcon, chartRef, emptyState]);

  return (
    <ChartWrapper id={title?.toLowerCase().trim().split(" ").join("-")}>
      <ChartHeader title={title} description={description} tooltip={tooltip} exportToCsv={exportToCsv} flex={0}>
        {headerSuffix}
      </ChartHeader>
      <Box width="100%" flex={1}>
        {isLoading ? (
          <ShimmerLoader sx={{ height: chartHeight }} />
        ) : (
          <Box
            display="flex"
            flexDirection={isSmallScreen ? "column" : "row"}
            alignItems={isSmallScreen ? "center" : "flex-start"}
            gap={9}
            padding={3}
          >
            <Box
              height={isSmallScreen ? 240 : 300}
              width={emptyState === true ? "100%" : isSmallScreen ? 240 : 300}
              maxWidth={emptyState === true ? "100%" : isSmallScreen ? 240 : 300}
              flexGrow={1}
            >
              <ECharts
                ref={chartRef}
                emptyState={emptyState}
                emptyStateText={emptyStateText}
                option={getPieChartOptions(
                  theme,
                  { series: filteredSeries, isPercent, currency, cryptoCurrency },
                  showLabelInEmphasis,
                )}
                padding={0}
                disableWatermark
              />
            </Box>
            {emptyState === true ? undefined : (
              <Box flex={1}>
                <PieChartCustomLegend
                  data={legendData}
                  onHover={(name: string, highlight: boolean) => handleLegendHover(chartRef, name, highlight)}
                  onClick={(name: string) => {
                    handleLegendClick(chartRef, name);
                    onClickLegendItem?.(name);
                  }}
                  showLegendCryptoIcon={showLegendCryptoIcon}
                  currency={currency}
                  isPercent={isPercent}
                  getLegendItemSubtitle={getLegendItemSubtitle}
                  formatLegendItemLabel={formatLegendItemLabel}
                  getLegendItemTooltip={getLegendItemTooltip}
                  cryptoCurrency={cryptoCurrency}
                />
              </Box>
            )}
          </Box>
        )}
      </Box>
    </ChartWrapper>
  );
};
