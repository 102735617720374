import { Box, Grid } from "@frontend/ui";
import { FC, useMemo } from "react";
import { useParams } from "react-router-dom";
import { PageHeader } from "@frontend/ui/page-header";
import { Loader } from "@frontend/ui/loader";
import AssetsHealthWidget, {
  MarketHealthWithTotalSupplyAndBorrow,
} from "src/components/assets-health-widget/assets-health-widget";
import { ValueCard } from "@frontend/ui/value-card";
import { WithUnifiedProductRedirect } from "src/utils/feature-flags";
import { Client } from "../../types";
import { useClientConfig } from "../../clients-config";
import { useWalletSupplyAndBorrow } from "./use-wallet-supply-borrow";
import EventsTable from "../../components/events-table/events-table";
import WalletPositionsTables from "./components/wallet-positions/wallet-positions-tables";
import { useAllMarketsQuery, useWalletLabelQuery } from "../../generated";

type SupplyAndBorrowType = { totalSupply: number; totalBorrow: number };

const WalletDetailsInner: FC = () => {
  let { address } = useParams<{ clientName: Client; address: string }>();
  address = address!.toLowerCase();
  const { walletDetails } = useClientConfig();
  const { isLoading: isLoadingSupplyAndBorrow, supplyAndBorrow } = useWalletSupplyAndBorrow(address);
  const { data: walletLabel } = useWalletLabelQuery({ variables: { address } });
  const { loading: isLoadingAllMarkets, data: allMarkets } = useAllMarketsQuery();

  const tokenData = useMemo(() => supplyAndBorrow?.tokenData || [], [supplyAndBorrow]);

  const marketHealth = useMemo(() => supplyAndBorrow?.marketHealth || [], [supplyAndBorrow]);

  const walletTotalSupplyAndBorrowSummary = tokenData?.reduce<SupplyAndBorrowType>(
    (result, val) => {
      const totalSupply: number = result?.totalSupply ? result.totalSupply : 0;
      const totalBorrow: number = result?.totalBorrow ? result.totalBorrow : 0;

      const updatedResult = {
        totalSupply: totalSupply + val.supplyAmountInUsd + val.collateralAmountInUsd,
        totalBorrow: totalBorrow + val.borrowAmountInUsd,
      };

      return updatedResult;
    },
    { totalSupply: 0, totalBorrow: 0 },
  );

  const marketHealthWithTotalSupplyAndBorrow = marketHealth?.reduce<MarketHealthWithTotalSupplyAndBorrow[]>(
    (result, val) => {
      const { market } = val;
      const marketTokenData = tokenData.filter((t) => market.id === t.market.id);
      result.push({
        market: val.market,
        healthFactor: val.healthFactor || 0,
        totalCollateral: marketTokenData.length
          ? marketTokenData.reduce((acc, t) => acc + t.collateralAmountInUsd, 0)
          : undefined,
        totalSupply: marketTokenData.length
          ? marketTokenData.reduce((acc, t) => acc + t.supplyAmountInUsd, 0)
          : undefined,
        totalBorrow: marketTokenData.length
          ? marketTokenData.reduce((acc, t) => acc + t.borrowAmountInUsd, 0)
          : undefined,
      });

      return result;
    },
    [],
  );

  const isLoading = isLoadingSupplyAndBorrow || isLoadingAllMarkets;

  const walletMarkets = allMarkets?.allMarkets.filter((m) => tokenData.find((t) => t.market.id === m.id));

  return (
    <Box
      sx={{
        display: "flex",
        gap: "24px",
        flexDirection: "column",
      }}
    >
      <Box display="flex" flexDirection="row">
        <PageHeader
          pageTitle={walletLabel?.walletLabel || "Wallet"}
          extraData={{
            text: "",
            link: {
              text: `${address}`,
              href: `https://debank.com/profile/${address}`,
              isExternal: true,
            },
            allowCopy: true,
          }}
        />
      </Box>
      {isLoading ? (
        <Loader />
      ) : (
        <Box display="flex" flexDirection="column">
          <Grid container>
            <Grid item xs={12} sm={6}>
              <ValueCard
                title="Total Supply"
                id="wallet-total-supply"
                value={
                  walletTotalSupplyAndBorrowSummary?.totalSupply !== undefined
                    ? walletTotalSupplyAndBorrowSummary?.totalSupply
                    : "N/A"
                }
                currency="USD"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ValueCard
                title="Total Borrow"
                id="wallet-total-borrow"
                value={
                  walletTotalSupplyAndBorrowSummary?.totalBorrow !== undefined
                    ? walletTotalSupplyAndBorrowSummary?.totalBorrow
                    : "N/A"
                }
                currency="USD"
              />
            </Grid>
          </Grid>

          {marketHealthWithTotalSupplyAndBorrow && !!marketHealthWithTotalSupplyAndBorrow.length && (
            <AssetsHealthWidget
              markets={allMarkets?.allMarkets}
              marketHealth={marketHealthWithTotalSupplyAndBorrow}
              eModeCategories={supplyAndBorrow?.eModeCategories ? supplyAndBorrow?.eModeCategories : undefined}
            />
          )}
          {supplyAndBorrow && allMarkets && (
            <WalletPositionsTables data={tokenData} metadata={address} markets={allMarkets.allMarkets} />
          )}
          {walletDetails?.tables.includes("recentEvents") && (
            <Box>
              <EventsTable eventsQuery={{ walletAddress: address }} metadata={address} />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

const WalletDetails = () => (
  <WithUnifiedProductRedirect>
    <WalletDetailsInner />
  </WithUnifiedProductRedirect>
);

export default WalletDetails;
