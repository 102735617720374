import { useMemo } from "react";
import { useAuth } from "@frontend/ui/auth";
import { Header, RenderData } from "@frontend/types";
import { assetCell, ChaosTable, currencyCell, dateCell, externalLinkAddressCell, textCell } from "@frontend/ui";
import { RouteParams, RoutePath } from "src/config/routes";
import { useParams } from "react-router-dom";
import { Chain } from "../../../../../ccar-lending-page/generated";
import { EventsTableDataProps, useEventsTableData } from "./use-events-table-data";
import { getEventStrategyDisplayName } from "../../../../utils";
import { EventTypeFilter, SortEventsBy } from "../../../../generated";
import { ETHERFI_APPROVED_ROLE } from "../../../../../../utils/consts";

type Props = {
  title: string;
  hideStrategyColumn?: boolean;
  hideAddressColumn?: boolean;
  showFilters?: boolean;
} & EventsTableDataProps;

const EventsTable = ({
  title,
  hideStrategyColumn,
  hideAddressColumn,
  showExportCsv,
  showFilters,
  ...tableDataProps
}: Props) => {
  const { events, loading, onPageChange, onSortChange, onSearch } = useEventsTableData(tableDataProps);
  const { clientName } = useParams();
  const auth = useAuth();

  const drillDown =
    auth.hasRole(ETHERFI_APPROVED_ROLE) &&
    (tableDataProps.eventType === EventTypeFilter.WithdrawalProcessed ||
      tableDataProps.eventType === EventTypeFilter.Deposit);

  const getRowHref = (rowId: number) => {
    const address = events[rowId]?.userAddress;

    if (!address) {
      return "";
    }
    if (tableDataProps.eventType === EventTypeFilter.WithdrawalProcessed) {
      return RoutePath.Risk.WithdrawalDrillDown.replace(RouteParams.ClientName, clientName!).replace(
        RouteParams.Address,
        address,
      );
    }
    return RoutePath.Risk.DepositDrillDown.replace(RouteParams.ClientName, clientName!).replace(
      RouteParams.Address,
      address,
    );
  };

  const headers: Header[] = useMemo(
    () => [
      {
        renderType: "TEXT",
        text: "Date",
        width: "200px",
        field: SortEventsBy.Timestamp,
        isDate: true,
        isFullDate: true,
        showTime: true,
      },
      ...(hideAddressColumn
        ? []
        : ([
            {
              renderType: "TEXT",
              text: tableDataProps.eventType === EventTypeFilter.Deposit ? "Depositor Address" : "Receiving Address",
              nonSortable: true,
              width: "200px",
              hideFilter: true,
            },
          ] as Header[])),
      {
        renderType: "TEXT",
        text: "Block Number",
        nonSortable: true,
        hideFilter: true,
      },
      ...(hideStrategyColumn
        ? []
        : ([
            {
              renderType: "TEXT",
              text: "Strategy",
              nonSortable: true,
              hideFilter: true,
            },
          ] as Header[])),
      {
        renderType: "TEXT",
        text: "Asset",
        nonSortable: true,
        hideFilter: true,
      },
      {
        renderType: "TEXT",
        text: "Amount (ETH)",
        field: SortEventsBy.AmountEth,
        sortOpposite: true,
        hideFilter: true,
      },
      {
        renderType: "TEXT",
        text: "Amount (Token)",
        nonSortable: true,
        hideFilter: true,
      },
      {
        renderType: "TEXT",
        text: "Amount (USD)",
        field: SortEventsBy.AmountUsd,
        sortOpposite: true,
        hideFilter: true,
      },
      {
        renderType: "TEXT",
        text: "TX Hash",
        nonSortable: true,
        width: "200px",
        hideFilter: true,
      },
    ],
    [hideStrategyColumn, hideAddressColumn, tableDataProps.eventType],
  );

  const tableData: RenderData[][] = (events || []).map((e) => [
    dateCell(new Date(e.timestamp)),
    ...(hideAddressColumn ? [] : ([externalLinkAddressCell(e.userAddress, Chain.Ethereum, "account")] as RenderData[])),
    textCell(e.blockNumber.toString(), e.blockNumber),
    ...(hideStrategyColumn ? [] : [e.strategy ? textCell(getEventStrategyDisplayName(e.strategy)) : textCell("-")]),
    assetCell(e.token),
    e.amountEth ? currencyCell(Math.abs(e.amountEth), { cryptoCurrency: "ETH" }) : textCell("-"),
    e.tokenAmount ? currencyCell(Math.abs(e.tokenAmount), { cryptoCurrency: e.token }) : textCell("-"),
    e.amountUsd ? currencyCell(Math.abs(e.amountUsd)) : textCell("-"),
    externalLinkAddressCell(e.txHash, Chain.Ethereum, "tx"),
  ]);

  return (
    <ChaosTable
      title={title}
      initialSortBy={0}
      isInitialSortDesc
      isFullHeight
      showSearch
      pageSize={10}
      headers={headers}
      data={tableData}
      isLoading={loading}
      onSortChange={onSortChange}
      onPageChange={onPageChange}
      onSearch={onSearch}
      rowHref={drillDown ? getRowHref : undefined}
      showRowChevron={drillDown}
      serchbarPlaceholder="Search"
      isFilterHidden={!showFilters}
      isSettingsHidden={!showExportCsv}
    />
  );
};

export default EventsTable;
