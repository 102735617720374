import { Box } from "@frontend/ui";
import { Route, Routes } from "react-router-dom";
import { PageTracker } from "src/components/page-tracker";
import { RouteParams } from "src/config/route-params";
import { useFeatureFlag } from "src/utils/feature-flags";
import { PageHeader } from "@frontend/ui/page-header";
import { PoolDetailsDrawer } from "../pool-details";
import { PoolsByProtocolCharts, PoolsTable } from "./components";

type Props = {
  asset: string;
  hideHeader?: boolean;
};

const PoolsPage = PageTracker(({ asset, hideHeader }: Props) => {
  const showAggregatedPoolsCharts = useFeatureFlag("agg-pools-charts");

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      {!hideHeader && <PageHeader showBackButton={false} pageTitle="Pools" />}
      {showAggregatedPoolsCharts && <PoolsByProtocolCharts asset={asset} />}
      <PoolsTable asset={asset} />
      <Routes>
        <Route path={`/${RouteParams.PoolId}`} element={<PoolDetailsDrawer />} />
      </Routes>
    </Box>
  );
});

export const PoolsTab = (props: Props) => <PoolsPage {...props} />;
