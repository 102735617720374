import { Header, RenderData } from "@frontend/types";
import { ChaosTable, numberCell, percentCell, textCell } from "@frontend/ui";
import { formatAddressCompact } from "@frontend/ui/utils/formatters";
import { RouteParams, RoutePath } from "src/config/routes";
import { useParams } from "react-router-dom";
import { Chain } from "../../../generated";
import { useRewardClaimersData } from "./use-reward-claimers-data";

const headers: Header[] = [
  {
    renderType: "TEXT",
    text: "Address",
    nonSortable: true,
  },
  {
    renderType: "TEXT",
    text: "APT claimed from protocol",
    nonSortable: true,
  },
  {
    renderType: "TEXT",
    text: "APT Claimed Across all Protocols",
    nonSortable: true,
  },
  {
    renderType: "TEXT",
    text: "Share of Total Claimed on Protocol",
    nonSortable: true,
  },
  {
    renderType: "TEXT",
    text: "Number of protocols claimed from",
    nonSortable: true,
  },
];

type Props = {
  chain: Chain;
  selectedProtocol: string;
};

export const AptRewardClaimersTable = ({ chain, selectedProtocol }: Props) => {
  const { protocol, clientName } = useParams();
  const { rewardClaimers, loading, onPageChange } = useRewardClaimersData({ chain, selectedProtocol });
  const tableData: RenderData[][] = (rewardClaimers || []).map((row) => [
    textCell(formatAddressCompact(row.address)),
    numberCell(row.aptClaimedFromProtocol),
    numberCell(row.aptClaimedFromAllProtocols),
    percentCell(row.shareOfTotalClaimedOnProtocol),
    numberCell(row.numberOfDifferentProtocolUsed),
  ]);

  const handleOnClickRow = (rowIdx: number) => {
    const row = (rewardClaimers || [])[rowIdx];

    if (!row) return "";

    return RoutePath.Risk.RewardClaimerDetails.replace(RouteParams.ClientName, clientName!)
      .replace(RouteParams.protocol, protocol!)
      .replace(RouteParams.Address, row.address);
  };

  return (
    <ChaosTable
      title="APT Reward Claimers"
      headers={headers}
      data={tableData}
      isLoading={loading}
      showRowChevron
      isFilterHidden
      isFullHeight
      pageSize={10}
      rowHref={handleOnClickRow}
      onPageChange={onPageChange}
    />
  );
};
