import ChainCategoryHistoryChart from "../../components/chain-category-history-chart";
import { Chain, TimeSpan, useNativeTokenDistributionOverTimeQuery } from "../../../generated";

type Props = {
  chain: Chain;
  timeSpan: TimeSpan;
};

export const AptDistributionPerProtocolOverTime = ({ chain, timeSpan }: Props) => {
  const { data, loading } = useNativeTokenDistributionOverTimeQuery({
    variables: { chain, daysAgo: null },
  });

  return (
    <ChainCategoryHistoryChart
      data={data?.nativeTokenDistributionOverTime}
      title="APT Distribution per Protocol"
      yAxisLabel="APT"
      isLoading={loading}
      timeSpan={TimeSpan.Quarter}
      showMonthTooltip={false}
      cryptoCurrency="APT"
      type="line"
    />
  );
};
