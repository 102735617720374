import { CompositionOverTimeChart } from "@frontend/ui";
import { daysAgoByTimeSpan } from "@frontend/ui/echarts/utils";
import { Chain, TimeSpan, useChainDistributionOverTimeQuery } from "../../../generated";

type Props = {
  chain: Chain;
  timeSpan: TimeSpan;
};

export const TotalActivityOvertimeForRewardClaimers = ({ chain, timeSpan }: Props) => {
  const { data, loading } = useChainDistributionOverTimeQuery({
    variables: { chain, daysAgo: daysAgoByTimeSpan[timeSpan] },
  });

  const series = (data?.chainDistributionOverTime || []).reduce(
    (acc, point) => {
      const { timestamp, bridgedOut, claimed, sold } = point;

      acc.bridgedOut = acc.bridgedOut || [];
      acc.claimed = acc.claimed || [];
      acc.sold = acc.sold || [];

      acc.bridgedOut.push([timestamp, bridgedOut]);
      acc.claimed.push([timestamp, claimed]);
      acc.sold.push([timestamp, sold]);

      return acc;
    },
    {} as Record<string, [number, number][]>,
  );

  return (
    <CompositionOverTimeChart
      title="Total Bridge Activity for Reward Claimers"
      series={[
        { data: series.bridgedOut ?? [], type: "line", label: "Bridged Out" },
        { data: series.claimed ?? [], type: "line", label: "Claimed" },
        { data: series.sold ?? [], type: "line", label: "Sold" },
      ]}
      isLoading={loading}
      cryptoCurrency="APT"
    />
  );
};
