export enum CcarClient {
  AAVE_V3 = "AAVE_V3",
  AAVE_V2 = "AAVE_V2",
  BENQI = "BENQI",
  RADIANT = "RADIANT",
  GMX_ARBITRUM = "GMX_ARBITRUM",
  GMX_AVALANCHE = "GMX_AVALANCHE",
  GMX_V2_ARBITRUM = "GMX_V2_ARBITRUM",
  GMX_V2_AVALANCHE = "GMX_V2_AVALANCHE",
  VENUS = "VENUS",
  DYDXV4 = "DYDXV4",
  OSTIUM = "OSTIUM",
  SEAMLESS = "SEAMLESS",
  BLUEFIN = "BLUEFIN",
  ZEROLEND = "ZEROLEND",
  NFTPERP = "NFTPERP",
  JUPITER = "JUPITER",
  GEARBOX = "GEARBOX",
  ETHERFI = "ETHERFI",
  RENZO = "RENZO",
}

export type ProtocolCategory = "Borrow & Lend" | "Perpetuals" | "Restaking";

export const mapProtocolToData: Record<
  CcarClient,
  { icon: string; version: string; name: string; category: ProtocolCategory }
> = {
  [CcarClient.AAVE_V3]: {
    icon: "aave",
    version: "V3",
    name: "Aave",
    category: "Borrow & Lend",
  },
  [CcarClient.AAVE_V2]: {
    icon: "aave",
    version: "V2",
    name: "Aave",
    category: "Borrow & Lend",
  },
  [CcarClient.BENQI]: {
    icon: "benqi",
    version: "",
    name: "Benqi",
    category: "Borrow & Lend",
  },
  [CcarClient.RADIANT]: {
    icon: "radiant",
    version: "",
    name: "Radiant",
    category: "Borrow & Lend",
  },
  [CcarClient.GMX_ARBITRUM]: {
    icon: "gmx",
    version: "V1 Arbitrum",
    name: "GMX",
    category: "Perpetuals",
  },
  [CcarClient.GMX_AVALANCHE]: {
    icon: "gmx",
    version: "V1 Avalanche",
    name: "GMX",
    category: "Perpetuals",
  },
  [CcarClient.GMX_V2_ARBITRUM]: {
    icon: "gmx",
    version: "V2 Arbitrum",
    name: "GMX",
    category: "Perpetuals",
  },
  [CcarClient.GMX_V2_AVALANCHE]: {
    icon: "gmx",
    version: "V2 Avalanche",
    name: "GMX",
    category: "Perpetuals",
  },
  [CcarClient.VENUS]: {
    icon: "venus",
    version: "",
    name: "Venus",
    category: "Borrow & Lend",
  },
  [CcarClient.DYDXV4]: {
    icon: "dydx",
    version: "",
    name: "dYdX",
    category: "Perpetuals",
  },
  [CcarClient.OSTIUM]: {
    icon: "ostium",
    version: "Mainnet",
    name: "Ostium",
    category: "Perpetuals",
  },
  [CcarClient.SEAMLESS]: {
    icon: "seamless",
    version: "",
    name: "Seamless",
    category: "Borrow & Lend",
  },
  [CcarClient.BLUEFIN]: {
    icon: "bluefin",
    version: "",
    name: "Bluefin",
    category: "Perpetuals",
  },
  [CcarClient.ZEROLEND]: {
    icon: "zerolend",
    version: "",
    name: "ZeroLend",
    category: "Borrow & Lend",
  },
  [CcarClient.NFTPERP]: {
    icon: "nftperp",
    version: "",
    name: "NFTPerp",
    category: "Perpetuals",
  },
  [CcarClient.JUPITER]: {
    icon: "jupiter",
    version: "",
    name: "Jupiter",
    category: "Perpetuals",
  },
  [CcarClient.GEARBOX]: {
    icon: "gearbox",
    version: "",
    name: "Gearbox",
    category: "Borrow & Lend",
  },
  [CcarClient.ETHERFI]: {
    icon: "etherfi",
    version: "",
    name: "EtherFi",
    category: "Restaking",
  },
  [CcarClient.RENZO]: {
    icon: "renzo",
    version: "",
    name: "Renzo",
    category: "Restaking",
  },
};
