import { StackedBarChart } from "@frontend/ui";
import { Chain, useRetentionRateByProtocolQuery } from "../../../generated";

export const RetentionRatePerProtocol = ({ chain }: { chain: Chain }) => {
  const { data, loading } = useRetentionRateByProtocolQuery({
    variables: { chain },
  });

  return (
    <StackedBarChart
      chartHeight={348}
      title="Retention Rate per Protocol"
      isLoading={loading}
      series={[
        {
          label: "Retention Rate",
          data: [...(data?.retentionRateByProtocol || [])]
            .sort((a, b) => b.value - a.value)
            .map((c) => [c.category, c.value]),
        },
      ]}
      showXAxisLine={false}
      showLegend={false}
      isPercent
      rotateXAxis={45}
    />
  );
};
