import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ErrorBoundaryComponent } from "@frontend/ui/error-boundary";
import { Error } from "@frontend/ui/error";
import { RoutePath } from "./config/routes";
import { CommunityDashboardLayout } from "./components/layouts";
import { CCARLendingRoutes } from "./pages/ccar-lending-page";
import { CCARPerpetualsPage } from "./pages/ccar-perpetuals-page";
import { LpDashboardPage } from "./pages/lp-dashboard-page";
import { CommunityAnalyticsPublicPage } from "./pages/community-analytics-public-page";
import { BenqiCalculatorPage } from "./pages/benqi-calculator-page";
import { PrivacyPage } from "./pages/privacy-page";
import { TermsPage } from "./pages/terms-page";
import { IncentivesOptimizationRoutes } from "./pages/incentives-optimization-page";
import { TwapRoutes } from "./pages/uniswap/twap-page";
import { LpStrategyCalculatorRoutes } from "./pages/uniswap/lp-strategy-calculator-page";
import { ScrollToTop } from "./utils/scroll-to-top";
import { BenqiReportPage } from "./pages/benqi-report-page";
import { OraclePortalRoutes } from "./pages/oracle-portal";
import { RiskMonitoringRoutes } from "./components/routes/risk-monitoring-routes";
import { AuthCallbackPage } from "./pages/auth-callback";
import HomPageRedirect from "./pages/community-analytics-page/homepage-redirect";
import { CCARAlertsPortalRoutes } from "./pages/alerts-portal-page/routes/alerts-portal-routes";
import { HomePage } from "./pages/home-page";
import { ChaosApolloProvider } from "./utils/graphql";
import { FeatureFlagsProvider } from "./utils/feature-flags";

export const RenderRoutes = () => (
  <Router>
    <ScrollToTop />
    <ErrorBoundaryComponent>
      <Routes>
        <Route path={RoutePath.Privacy} element={<PrivacyPage />} />
        <Route path={RoutePath.Terms} element={<TermsPage />} />
        <Route path={RoutePath.AuthCallback} element={<AuthCallbackPage />} />
        <Route
          path={RoutePath.Home}
          element={
            <ChaosApolloProvider product="common">
              <FeatureFlagsProvider>
                <HomePage />
              </FeatureFlagsProvider>
            </ChaosApolloProvider>
          }
        />
        <Route
          path={RoutePath.BenqiCalculator}
          element={
            <CommunityDashboardLayout>
              <BenqiCalculatorPage />
            </CommunityDashboardLayout>
          }
        />
        <Route path={RoutePath.BenqiReport} element={<BenqiReportPage />} />
        <Route
          path={`${RoutePath.IncentivesOptimization.Home}/*`}
          element={
            <CommunityDashboardLayout>
              <IncentivesOptimizationRoutes />
            </CommunityDashboardLayout>
          }
        />
        <Route path={`${RoutePath.OraclePortal.Home}/*`} element={<OraclePortalRoutes />} />
        <Route path={`${RoutePath.AlertsPortal.Home}/*`} element={<CCARAlertsPortalRoutes />} />
        <Route path={`${RoutePath.CCARLending.Home}/*`} element={<CCARLendingRoutes />} />
        <Route path={`${RoutePath.Risk.Home}/*`} element={<RiskMonitoringRoutes />} />
        <Route path={`${RoutePath.CCARPerpetuals.Home}/*`} element={<CCARPerpetualsPage />} />
        <Route path={`${RoutePath.LpDashboard.Home}/*`} element={<LpDashboardPage />} />
        <Route
          path={`${RoutePath.Twap.Home}/*`}
          element={
            <CommunityDashboardLayout>
              <TwapRoutes />
            </CommunityDashboardLayout>
          }
        />
        <Route
          path={`${RoutePath.LpStrategyCalculator.Home}/*`}
          element={
            <CommunityDashboardLayout>
              <LpStrategyCalculatorRoutes />
            </CommunityDashboardLayout>
          }
        />
        <Route
          path={RoutePath.CommunityAnalytics.Public}
          element={
            <CommunityDashboardLayout>
              <CommunityAnalyticsPublicPage />
            </CommunityDashboardLayout>
          }
        />
        <Route path={RoutePath.CommunityAnalytics.Home} element={<HomPageRedirect />} />
        <Route element={<Error errorTitle="404" errorMessage="Page Not Found" />} />
      </Routes>
    </ErrorBoundaryComponent>
  </Router>
);
