export const getEventIcon = (eventType: string): string | undefined => {
  if (
    eventType.includes("Supply") ||
    eventType.includes("Mint") ||
    eventType.includes("Deposit") ||
    eventType.includes("bridge in")
  ) {
    return "arrow-right";
  }
  if (
    eventType.includes("Borrow") ||
    eventType.includes("Liquid") ||
    eventType.includes("Redeem") ||
    eventType.includes("Withdraw") ||
    eventType.includes("claim") ||
    eventType.includes("sale") ||
    eventType.includes("bridge out")
  ) {
    return "arrow-left";
  }
  if (eventType.includes("Repay")) {
    return "repay-icon";
  }
  return undefined;
};

export const getEventDisplayName = (eventType: string): string | undefined => {
  if (eventType.includes("Supply") || eventType.includes("Deposit")) {
    return "Deposit";
  }
  if (eventType.includes("Borrow")) {
    return "Borrow";
  }
  if (eventType.includes("Repay")) {
    return "Repay";
  }
  if (eventType.includes("Redeem")) {
    return "Reedem Pay";
  }
  if (eventType.includes("Mint")) {
    return "Mint";
  }
  if (eventType.includes("Liquid")) {
    return "Liquidate";
  }
  if (eventType.includes("Withdraw")) {
    return "Withdraw";
  }
  if (eventType.includes("Teleport Received")) {
    return "Teleport Received";
  }
  if (eventType.includes("bridge in")) {
    return "Bridge In";
  }
  if (eventType.includes("bridge out")) {
    return "Bridge Out";
  }
  if (eventType.includes("claim")) {
    return "Claim";
  }
  if (eventType.includes("sale")) {
    return "Sale";
  }

  return eventType;
};
