import { StackedBarChart } from "@frontend/ui";
import { OverviewData } from "../use-overview-data";

type Props = {
  data: OverviewData;
};

const TvlByDappCategoryChart = ({ data }: Props) => (
  <StackedBarChart
    chartHeight={325}
    title="TVL By Dapp Category"
    isLoading={data.isLoadingTvlByDappCategory}
    series={[
      {
        label: "TVL",
        data: [...(data.tvlByDappCategory || [])].sort((a, b) => b.value - a.value).map((c) => [c.category, c.value]),
      },
    ]}
    showXAxisLine={false}
    showLegend={false}
  />
);

export default TvlByDappCategoryChart;
