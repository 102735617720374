import React, { ReactNode } from "react";
import { useParams } from "react-router-dom";
import { RouteParams, RoutePath } from "src/config/routes";
import { CommunityDashboardLayout } from "src/components/layouts";
import { useFeatureFlag } from "src/utils/feature-flags";
import { useClientConfig } from "../clients-config";

export const RiskTabsLayout: React.FunctionComponent<{ children: ReactNode }> = ({ children }) => {
  const {
    isMultiMarket,
    overview,
    liquidations,
    marketDetails,
    assetDetails,
    wallets,
    riskExplorer,
    stableCoin,
    alerts,
    reserves,
    chainsTab,
  } = useClientConfig();
  const { clientName } = useParams();
  const replaceParams = (path: string) => path.replace(RouteParams.ClientName, clientName!);
  const poolsEnabled = useFeatureFlag("stablecoin_pools");
  const usdoEnabled = useFeatureFlag("usdo");
  const assetDetailsEnabled = useFeatureFlag("asset_details");
  const isUSDO = stableCoin?.asset.toLowerCase().match("usdo");
  const shouldShowStablecoin = isUSDO ? usdoEnabled : true;

  return (
    <CommunityDashboardLayout
      showClientToolsDropdown={false}
      tabs={[
        ...(overview
          ? [
              {
                label: "Overview",
                path: replaceParams(RoutePath.Risk.Overview),
              },
            ]
          : []),
        ...(marketDetails
          ? [
              {
                label: isMultiMarket ? "Markets" : "Assets",
                path: replaceParams(RoutePath.Risk.Markets),
              },
            ]
          : []),
        ...(chainsTab
          ? [
              {
                label: "Chains",
                path: replaceParams(RoutePath.Risk.Chains),
              },
            ]
          : []),
        ...(assetDetails && assetDetailsEnabled
          ? [
              {
                label: "Assets",
                path: replaceParams(RoutePath.Risk.Assets),
              },
            ]
          : []),
        ...(wallets
          ? [
              {
                label: "Wallets",
                path: replaceParams(RoutePath.Risk.Wallets),
              },
            ]
          : []),
        ...(liquidations
          ? [
              {
                label: "Liquidations",
                isActive: (path: string) => path.includes(replaceParams(RoutePath.Risk.Liquidations)),
                path: replaceParams(RoutePath.Risk.Liquidations),
              },
            ]
          : []),
        ...(reserves
          ? [
              {
                label: "Reserves",
                path: replaceParams(RoutePath.Risk.Reserves),
              },
            ]
          : []),
        ...(riskExplorer
          ? [
              {
                label: "Risk Explorer",
                path: replaceParams(RoutePath.Risk.RiskExplorer),
              },
            ]
          : []),
        ...(shouldShowStablecoin && stableCoin && !stableCoin.showInOverview
          ? [
              {
                label: stableCoin.asset,
                badge: stableCoin.beta ? "Beta" : undefined,
                path: replaceParams(RoutePath.Risk.StablecoinHome(stableCoin.asset)),
              },
            ]
          : []),
        ...(stableCoin?.hasPools && poolsEnabled && stableCoin.showInOverview
          ? [
              {
                label: "Pools",
                path: replaceParams(RoutePath.Risk.Pools),
              },
            ]
          : []),
        ...(alerts?.enabled
          ? [
              {
                label: "Alerts",
                path: replaceParams(RoutePath.Risk.Alerts),
              },
            ]
          : []),
      ]}
    >
      {children}
    </CommunityDashboardLayout>
  );
};
