import { Grid, Typography } from "@frontend/ui";
import { FC } from "react";
import { EModeInformation, MarketData, MarketHealth } from "src/pages/ccar-lending-page/generated";
import { MarketHealthScore } from "../market-health-score";

type AssetsHealthWidgetProps = {
  markets?: MarketData[];
  marketHealth: MarketHealth[];
  eModeCategories?: EModeInformation[];
};

export type MarketHealthWithTotalSupplyAndBorrow = MarketHealth & {
  totalCollateral?: number;
  totalSupply?: number;
  totalBorrow?: number;
};

const AssetsHealthWidget: FC<AssetsHealthWidgetProps> = ({ markets, marketHealth, eModeCategories }) => (
  <Grid container>
    <Grid xs={12} item borderBottom="1px solid" borderColor="greys.800">
      <Typography variant="h2" px={4} pb={2}>
        Markets
      </Typography>
    </Grid>
    {marketHealth?.map((market: MarketHealthWithTotalSupplyAndBorrow) => {
      const eModeCategory = eModeCategories?.find((c) => c.marketId === market.market.id);
      const marketData = markets?.find((m) => m.id === market.market.id);

      return (
        <Grid xs={12} sm={6} item borderRight="1px solid" borderColor="greys.800" py={4} px={4}>
          <MarketHealthScore
            key={Object.values(market.market).join("-")}
            {...market}
            marketData={marketData}
            eModeCategory={
              eModeCategory && { id: eModeCategory.categoryId.toString(), label: eModeCategory.categoryLabel }
            }
          />
        </Grid>
      );
    })}
  </Grid>
);

export default AssetsHealthWidget;
