import React, { createContext, useContext, useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { useFeatureFlagsQuery, FeatureFlag } from "src/pages/ccar-lending-page/generated";

type FeatureFlagKey =
  | "unified_product_redirect"
  | "borrow_supply_in_token"
  | "stablecoin_pools"
  | "asset-pools"
  | "crvusd"
  | "usdo"
  | "long_history"
  | "apy_history"
  | "recommendations-v2"
  | "asset_details"
  | "dydx-v4"
  | "seamless"
  | "zerolend"
  | "collateral-at-risk-history"
  | "wallets-amount-at-risk-history"
  | "bluefin"
  | "agg-pools-charts"
  | "agg-orders-charts"
  | "stablecoin-long-history"
  | "perpetuals-positions-analysis"
  | "accounts-tab"
  | "asset_distribution_history"
  | "nftperp"
  | "bad-debt-wallets-charts"
  | "wallets-at-risk-history"
  | "open-interest-drill-down-modal"
  | "jupiter"
  | "synfutures"
  | "aptos"
  | "ostium"
  | "etherfi"
  | "defiapp";

const FeatureFlagsContext = createContext<FeatureFlag[]>([]);

export const useFeatureFlags = (): FeatureFlag[] => {
  const flags = useContext(FeatureFlagsContext);
  return flags;
};

export const useFeatureFlag = (key: FeatureFlagKey): boolean => {
  const { clientName } = useParams();
  const flags = useContext(FeatureFlagsContext);
  const featureFlag = flags.find((f) => f.key === key);

  const clients = featureFlag?.clients;
  const enabled = featureFlag?.enabled || false;

  if (!clients) return enabled;

  const isClientInList = clients.includes(clientName!);

  return isClientInList ? enabled : !enabled;
};

export const FeatureFlagsProvider: React.FC = ({ children }) => {
  const { data, loading } = useFeatureFlagsQuery();
  const [featureFlags, setFeatureFlags] = useState<FeatureFlag[]>([]);

  useEffect(() => {
    if (data?.featureFlags) {
      setFeatureFlags(data?.featureFlags);
    }
  }, [data?.featureFlags]);

  return (
    <FeatureFlagsContext.Provider value={featureFlags}>
      {(!!featureFlags.length || !loading) && children}
    </FeatureFlagsContext.Provider>
  );
};

export const useShouldPerformUnifiedRedirect = () => {
  const isProductRedirectEnabled = useFeatureFlag("unified_product_redirect");
  return isProductRedirectEnabled && !!window.location.pathname.match("/ccar/");
};

export const WithUnifiedProductRedirect: React.FC = ({ children }) => {
  const shouldRedirect = useShouldPerformUnifiedRedirect();
  if (shouldRedirect) {
    return <Navigate to={window.location.pathname.replace("/ccar", "/risk")} replace />;
  }

  return <div className="unified-product-redirect">{children}</div>;
};
