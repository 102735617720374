import { CompositionOverTimeChart, CustomReactSelect } from "@frontend/ui";
import { NumberChartSeries } from "@frontend/ui/echarts/types";
import { useEffect, useMemo, useState } from "react";

import { Chain, useApyVsDepositAndWithdrawalQuery } from "../../../generated";

type Props = {
  chain: Chain;
  selectedProtocol: string;
  daysAgo: number;
};

export const ApyVsDepositAndWithdrawal = ({ chain, selectedProtocol, daysAgo }: Props) => {
  const { data, loading } = useApyVsDepositAndWithdrawalQuery({
    variables: { chain, selectedProtocol, daysAgo },
  });
  const [selectedToken, setSelectedToken] = useState<string>();

  const values = useMemo(() => data?.apyVsDepositAndWithdrawal || [], [data]);

  const tokens = useMemo(
    () => values.map((v) => v.token).filter((value, index, array) => array.indexOf(value) === index),
    [values],
  );

  const tokenOptions = tokens.map((t) => ({ label: t, value: t }));

  // Dynamically set the initial selected token when tokens become available
  useEffect(() => {
    setSelectedToken(tokens[0]);
  }, [tokens]);

  if (!loading && (!data || !data.apyVsDepositAndWithdrawal || !data.apyVsDepositAndWithdrawal.length)) {
    return null;
  }

  if (!selectedToken) {
    return null; // Wait until selectedToken is set
  }

  const depositData: [number, number][] = [];
  const withdrawalData: [number, number][] = [];
  const apyData: [number, number][] = [];
  values
    .filter((v) => v.token === selectedToken)
    .forEach((v) => {
      v.categories.forEach((obj) => {
        const point = [v.timestamp, obj.value] as [number, number];

        if (obj.category === "Deposit") {
          depositData.push(point);
        } else if (obj.category === "Withdraw") {
          withdrawalData.push([v.timestamp, -obj.value]); // Negate withdrawal value
        } else if (obj.category === "APY") {
          apyData.push(point);
        }
      });
    });

  return (
    <CompositionOverTimeChart
      isLoading={loading}
      title="APY vs Deposits / Withdrawals"
      cryptoCurrency={selectedToken}
      dualAxis
      yAxisIsPercentRight
      hideTotal
      series={[
        {
          name: "Deposit",
          label: "Deposit",
          type: "bar",
          stack: "Asset",
          yAxisIndex: 0,
          data: depositData,
        } as NumberChartSeries,
        {
          name: "Withdrawals",
          label: "Withdrawals",
          type: "bar",
          stack: "Asset",
          yAxisIndex: 0,
          data: withdrawalData,
        } as NumberChartSeries,
        {
          name: "APY",
          label: "APY",
          type: "line",
          yAxisIndex: 1,
          data: apyData,
        } as NumberChartSeries,
      ]}
      headerSuffix={
        selectedToken && (
          <CustomReactSelect
            options={tokenOptions}
            value={tokenOptions.filter((t) => t.value === selectedToken)}
            onChange={(t) => {
              if (!t) return;
              setSelectedToken(t.value);
            }}
            isSearchable={false}
          />
        )
      }
    />
  );
};
