import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, ClickAwayListener, Popper, Typography } from "@mui/material";
import { alpha } from "@mui/system";
import { Box } from "../box";
import { useTheme } from "../theme";
import { Button } from "../button";
import {
  TableFilterAutocomplete,
  TableFilterOptions,
  TableFilterSlider,
  TableFilterText,
} from "./table-filter-content";
import { CustomIcon } from "../custom-icon";

export interface BaseFilter {
  title?: string;
  fieldName: string;
  fieldIndex: number;
  type: "text" | "range" | "options";
  isApplied: boolean;
  prefix?: string;
  suffix?: string;
  isDate?: boolean;
  isFullDate?: boolean;
  showTime?: boolean;
  isDuration?: boolean;
}

export interface FilterText extends BaseFilter {
  type: "text";
  value?: string;
}

export interface FilterRange extends BaseFilter {
  type: "range";
  min?: number;
  max?: number;
  range: [number, number];
  stepSize?: number;
}

export interface FilterPicker extends BaseFilter {
  type: "options";
  options: {
    name: string;
    value?: string;
    customIcon?: string;
    cryptoIcon?: string;
    isHidden: boolean;
    isFixed?: boolean;
  }[];
  isAutocomplete?: boolean;
}

export type Filter = FilterText | FilterRange | FilterPicker;

interface TableFilterInterface {
  isOpen: boolean;
  anchorEl?: HTMLElement | null;
  filters: Filter[];
  onChange: (filterValues: Record<number, Filter>) => void;
  close: () => void;
}

export const TableFilter: FC<TableFilterInterface> = ({ isOpen, anchorEl, filters, onChange, close }) => {
  const [filterValues, setFilterValues] = useState<Record<number, Filter>>(
    filters.reduce(
      (res, filter) => ({
        ...res,
        [filter.fieldIndex]: filter,
      }),
      {},
    ),
  );
  const [expandedIdx, setExpandedIdx] = useState<number | null>(0);
  const theme = useTheme();

  type Keys = "Escape" | "Enter";

  const keyActions: Record<Keys, () => void> = useMemo(
    () => ({
      Escape: () => {
        close();
      },
      Enter: () => {
        onChange(filterValues);
        close();
      },
    }),
    [close, filterValues, onChange],
  );

  const keyListener = useCallback(
    (e: KeyboardEvent) => {
      const action = keyActions[e.key as Keys];

      if (action) {
        action();
      }
    },
    [keyActions],
  );

  useEffect(() => {
    document.body.addEventListener("keyup", keyListener);
    return () => document.body.removeEventListener("keyup", keyListener);
  }, [isOpen, keyListener]);

  useEffect(
    () =>
      setFilterValues((curFilterValues) =>
        filters.reduce((res, filter) => {
          if (!filter.isApplied && curFilterValues[filter.fieldIndex]?.isApplied) {
            return {
              ...res,
              [filter.fieldIndex]: {
                ...curFilterValues[filter.fieldIndex],
                value: (filter as FilterText).value,
                range: (filter as FilterRange)?.range,
                options: (filter as FilterPicker).options,
                isApplied: false,
              },
            };
          }

          return {
            ...res,
            [filter.fieldIndex]: curFilterValues[filter.fieldIndex] ?? filter,
          };
        }, {}),
      ),
    [filters],
  );

  const accordions = !filterValues
    ? undefined
    : Object.values(filterValues)
        .filter((f) => !!f)
        .map((f) => ({
          label: f?.title || f?.fieldName,
          isApplied: f?.isApplied,
        }));

  return (
    <Popper
      open={isOpen}
      placement="bottom-end"
      modifiers={[
        {
          name: "offset",
          options: {
            offset: [12, 16],
          },
        },
      ]}
      anchorEl={anchorEl}
      style={{
        zIndex: theme.zIndex.modal + 1,
        borderRadius: 2,
        marginTop: 32,
        width: 400,
        overflow: "hidden",
        boxShadow: "0px 16px 32px rgba(0, 0, 0, 0.08)",
      }}
    >
      <ClickAwayListener onClickAway={close}>
        <Box
          borderRadius="inherit"
          sx={{
            backdropFilter: "blur(13px)",
            backgroundColor: alpha(theme.palette.greys["800"], 0.6),
          }}
        >
          <Box display="flex" justifyContent="space-between" alignItems="center" p={2} pb={1} height={60}>
            <Typography variant="label">Filters</Typography>
            <Box display="flex">
              <Button
                data-testid="filter-clear-btn"
                size="small"
                variant="text"
                sx={{ color: "text.primary" }}
                onClick={() => {
                  const clearedFilterValues = filters.reduce(
                    (res, filter) => ({
                      ...res,
                      [filter.fieldIndex]: {
                        ...filter,
                        isApplied: false,
                        ...(filter.type === "options" && {
                          options: filter.options.map((o) => ({ ...o, isHidden: true })),
                        }),
                        /** Reset range to min/max values */
                        ...(filter.type === "range" &&
                          typeof filter.min === "number" &&
                          typeof filter.max === "number" && {
                            range: [filter.min, filter.max],
                          }),
                      },
                    }),
                    {},
                  );
                  setFilterValues(clearedFilterValues);
                }}
              >
                Clear All
              </Button>
              <Button
                data-testid="filter-apply-btn"
                size="small"
                variant="text"
                sx={{ color: "primary.main" }}
                onClick={() => {
                  onChange(filterValues);
                  close();
                }}
              >
                Apply
              </Button>
            </Box>
          </Box>

          <Box display="flex" flexDirection="column" justifyContent="space-between">
            {accordions?.map((accordion, i) => {
              const filter = filters[i];
              if (!filter) return null;
              let content;
              switch (filter.type) {
                case "range":
                  content = (
                    <TableFilterSlider
                      {...{
                        filter,
                        key: filter.fieldName,
                        setFilterValues,
                      }}
                      min={filter.min}
                      max={filter.max}
                      filter={filter}
                      range={(filterValues[filter.fieldIndex] as FilterRange)?.range}
                    />
                  );
                  break;
                case "options":
                  if (filter.isAutocomplete) {
                    content = (
                      <TableFilterAutocomplete
                        {...{
                          filter,
                          key: filter.fieldName,
                          setFilterValues,
                        }}
                        options={(filterValues[filter.fieldIndex] as FilterPicker)?.options}
                      />
                    );
                  } else {
                    content = (
                      <TableFilterOptions
                        {...{
                          filter,
                          key: filter.fieldName,
                          setFilterValues,
                        }}
                        options={(filterValues[filter.fieldIndex] as FilterPicker)?.options}
                      />
                    );
                  }
                  break;
                default:
                  content = (
                    <TableFilterText
                      filter={filterValues[i] as FilterText}
                      key={filter.fieldName}
                      setFilterValues={setFilterValues}
                    />
                  );
              }
              const expanded = expandedIdx === i;
              return (
                <Accordion
                  key={accordion.label + String(isOpen)}
                  expanded={expanded}
                  onChange={() => setExpandedIdx(expandedIdx === i ? null : i)}
                >
                  <AccordionSummary
                    data-testid="filter-accordion-summary"
                    sx={{ display: "flex", alignItems: "center" }}
                    expandIcon={<CustomIcon icon="chevron-down-small" />}
                  >
                    <Typography variant="h5" data-testid="filter-accordion-label">
                      {accordion.label}
                    </Typography>{" "}
                    {accordion.isApplied && <Box sx={{ width: 8, height: 8, borderRadius: 2 }} />}
                  </AccordionSummary>
                  <AccordionDetails
                    data-testid={expanded ? "filter-accordion-detail" : null}
                    data-accordion-detail-for={accordion.label}
                  >
                    {content}
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </Box>
        </Box>
      </ClickAwayListener>
    </Popper>
  );
};
