import { Route, Routes, Navigate, useParams } from "react-router-dom";
import { Error } from "@frontend/ui/error";
import { RouteParams, RoutePath } from "src/config/routes";
import { TopBanner } from "src/pages/community-analytics-page/components/top-banner";
import { useAuth } from "@frontend/ui/auth";
import { RiskTabsLayout } from "../../components/risk-tabs-layout";
import {
  LiquidationsTab,
  OverviewTab,
  WalletsTab,
  WalletDetails,
  RiskExplorerTab,
  AlertsTab,
  AssetDetails,
  AssetsTab,
  StablecoinTab,
  PoolsTab,
  ReservesTab,
} from "../../tabs";
import { clientsConfig, useClientConfig } from "../../clients-config";
import { Client } from "../../types";
import MarketsRoutes from "../../tabs/markets-new/routes/routes";
import { replaceHomePath } from "./utils";
import { LiquidationsTabsLayout } from "../../tabs/liquidations/liquidations-tabs-layout";
import { ChainsRoutes } from "../../tabs/chains";
import { NotPermittedError } from "../../../../components/not-permitted-error";

export const RiskMonitoringLendingRoutes = () => {
  const { clientName } = useParams<{ clientName: Client }>();
  const {
    role,
    overview,
    marketDetails,
    chainsTab,
    assetDetails,
    wallets,
    riskExplorer,
    liquidations,
    reserves,
    stableCoin,
    alerts,
  } = useClientConfig();

  const auth = useAuth();

  const getDefaultRoute = () => {
    if (overview) {
      return RoutePath.Risk.Overview;
    }
    if (alerts) {
      return RoutePath.Risk.Alerts;
    }
    return RoutePath.Risk.RiskExplorer;
  };

  if (!Object.keys(clientsConfig).includes(clientName!)) {
    return <Error errorTitle="404" errorMessage="Page Not Found" />;
  }

  if (!auth.hasRole(role)) {
    return <NotPermittedError />;
  }

  return (
    <>
      <TopBanner />
      <Routes key={clientName}>
        <Route
          index
          element={<Navigate to={getDefaultRoute().replace(RouteParams.ClientName, clientName!)} replace />}
        />
        {overview && (
          <Route
            path={replaceHomePath(RoutePath.Risk.Overview)}
            element={
              <RiskTabsLayout>
                <OverviewTab />
              </RiskTabsLayout>
            }
          />
        )}
        {marketDetails && <Route path={`${replaceHomePath(RoutePath.Risk.Markets)}/*`} element={<MarketsRoutes />} />}
        {chainsTab && <Route path={`${replaceHomePath(RoutePath.Risk.Chains)}/*`} element={<ChainsRoutes />} />}
        {assetDetails && (
          <Route
            path={replaceHomePath(RoutePath.Risk.Assets)}
            element={
              <RiskTabsLayout>
                <AssetsTab />
              </RiskTabsLayout>
            }
          />
        )}
        {wallets && (
          <Route
            path={replaceHomePath(RoutePath.Risk.Wallets)}
            element={
              <RiskTabsLayout>
                <WalletsTab />
              </RiskTabsLayout>
            }
          />
        )}
        {liquidations && (
          <Route
            path={replaceHomePath(RoutePath.Risk.Liquidations)}
            element={
              <LiquidationsTabsLayout>
                <LiquidationsTab />
              </LiquidationsTabsLayout>
            }
          />
        )}
        {reserves && (
          <Route
            path={replaceHomePath(RoutePath.Risk.Reserves)}
            element={
              <RiskTabsLayout>
                <ReservesTab />
              </RiskTabsLayout>
            }
          />
        )}
        {riskExplorer && (
          <Route
            path={replaceHomePath(RoutePath.Risk.RiskExplorer)}
            element={
              <RiskTabsLayout>
                <RiskExplorerTab />
              </RiskTabsLayout>
            }
          />
        )}
        {stableCoin && !stableCoin.showInOverview && (
          <Route
            path={`${replaceHomePath(RoutePath.Risk.StablecoinHome(stableCoin.asset))}/*`}
            element={
              <RiskTabsLayout>
                <StablecoinTab />
              </RiskTabsLayout>
            }
          />
        )}
        {alerts?.enabled && (
          <Route
            path={`${replaceHomePath(RoutePath.Risk.Alerts)}/*`}
            element={
              <RiskTabsLayout>
                <AlertsTab />
              </RiskTabsLayout>
            }
          />
        )}
        {stableCoin?.hasPools && (
          <Route
            path={`${replaceHomePath(RoutePath.Risk.Pools)}/*`}
            element={
              <RiskTabsLayout>
                <PoolsTab asset={stableCoin.asset} />
              </RiskTabsLayout>
            }
          />
        )}
        <Route
          path={replaceHomePath(RoutePath.Risk.AssetDetails)}
          element={
            <RiskTabsLayout>
              <AssetDetails />
            </RiskTabsLayout>
          }
        />
        <Route
          path={replaceHomePath(RoutePath.Risk.WalletDetails)}
          element={
            <RiskTabsLayout>
              <WalletDetails />
            </RiskTabsLayout>
          }
        />
        <Route
          path="*"
          element={<Navigate to={getDefaultRoute().replace(RouteParams.ClientName, clientName!)} replace />}
        />
      </Routes>
    </>
  );
};
